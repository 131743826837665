import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//store
import Store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    /**
     * 会員一覧
     */
    path: '/member',
    name: 'Member',
    component:()=>import('../views/Member.vue')
  },
  {
    /**
     * 会員編集
     */
    path: '/member_edit',
    name: 'MemberEdit',
    component:()=>import('../views/MemberEdit.vue')
  },
  {
    /**
     * マスタ設定 
     */
    path: '/master',
    name: 'Master',
    component:()=>import('../views/Master.vue')
  },
  {
    /**
     *　システム設定 
     */
    path: '/system',
    name: 'System',
    component:()=>import('../views/System.vue')
  },
  {
    /**
     * 承認　
     */
    path: '/approval',
    name: 'Approval',
    component:()=>import('../views/Approval.vue')
    
  },
  {
     /**

     */
    path: '/approval_email',
    name: 'ApprovalEmail',
    component:()=>import('../views/ApprovalEmail.vue')
  },
  {
    /**
     * インフォメーション　
     */
    path: '/information',
    name: 'Information',
    component:()=>import('../views/Information.vue')
    
  },
  {
    /**
     * インフォメーション編集
     */
    path: '/informationEdit',
    name: 'InformationEdit',
    component:()=>import('../views/InformationEdit.vue')
    
  },
  {
    /**
     * ユーザー別設置件数集計
     */
    path: '/userbetsu',
    name: 'UserBetsu',
    component:()=>import('../views/UserBetsu.vue')
    
  },
  {
    /**
     * ユーザー別種類別サイズ別管理
     */
    path: '/syuruibetsu',
    name: 'SyuruiBetsu',
    component:()=>import('../views/SyuruiBetsu.vue')
    
  },
  {
    /**
     * 注文状況
     */
    path: '/chumon',
    name: 'Chumon',
    component:()=>import('../views/ChumonList.vue')
    
  },
  {
    /**
     * 発注状況
     */
    path: '/hatchu',
    name: 'Hatchu',
    component:()=>import('../views/HatchuList.vue')
    
  },
  {
    /**
     * 印刷用紙マスタ
     */
    path: '/printmst',
    name: 'PrintMaster',
    component:()=>import('../views/PrintMaster.vue')
    
  },
  {
    /**
     * 印刷用紙マスタ
     */
    path: '/printmstEdit',
    name: 'PrintMasterEdit',
    component:()=>import('../views/PrintMasterEdit.vue')
    
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/timeout',
    name: 'Timeout',
    component:()=>import('../views/Timeout.vue')
  },
  {
    path: '/sample',
    name: 'Sample',
    component: () => import('../views/About.vue')
  },
  /**資料管理 */
 {path: '/siryo', name:'siryoedit', component:()=>import('../views/SiryoEdit.vue')},
  /**お問合せ */
  { path: '/inquiries', name: 'inquiries', component: () => import('../views/Inquiries.vue') },
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from , next)=>{
  if(to.name === 'Timeout'){
    next()
  }else{
    if((to.name !== 'Login' ) && Store.state.token == '') next({name:'Login'})
    else next()

  }
})

export default router
