<template>
    <v-card class="mx-auto">
        <v-navigation-drawer
            app
            v-model="isMenu"
            clipped
            color="teal lighten-5"
            v-if="isLogin"
        >
            <v-container class="pl-0 pr-0">
                <v-list dense nav>
                    <v-toolbar elevation="2" color="teal lighten-1 mb-2" dense>
                        <v-toolbar-title class="text-subtitle-1"
                            >Menu</v-toolbar-title
                        >
                    </v-toolbar>

                    <v-list-item-group v-model="selectedMenu" color="teal darken-4">
                        <v-list-item
                            v-for="nav_list in nav_lists"
                            :key="nav_list.no"
                            :to="nav_list.link"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ nav_list.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="nav_list.name"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>


                    </v-list-item-group>
                </v-list>
            </v-container>
        </v-navigation-drawer>
    </v-card>
</template>
<style scope>
.menu-title {
    background-color: red im !important;
}
</style>
<script>
export default {
    data: () => ({
        drawer: null,
        selectedMenu: 1,
        nav_lists: [
            {
                name: "Top",
                icon: "mdi-home",
                link: "/",
                no: 1,
            },
            {
                name: "会員管理",
                icon: "mdi-account-multiple",
                link: "/member",
                no: 2,
            },
            {
                name: "会員別集計一覧",
                icon: "mdi-account-multiple",
                link: "/userbetsu",
                no: 3,
            },
            {
                name: "会員・コンテナ種類別集計一覧",
                icon: "mdi-account-multiple",
                link: "/syuruibetsu",
                no: 10,
            },
            {
                name: "QRコード印刷注文状況",
                icon: "mdi-qrcode",
                link: "/chumon",
                no: 7,
            },
            {
                name: "QRコード印刷発注状況",
                icon: "mdi-qrcode",
                link: "/hatchu",
                no: 8,
            },
            {
                name: "商品(用紙種別)マスタ",
                icon: "mdi-qrcode",
                link: "/printmst",
                no: 9,
            },
            {
                name: "システム設定",
                icon: "mdi-cog-outline",
                link: "/system",
                no: 4,
            },
            {
                name: "マスタ設定",
                icon: "mdi-database",
                link: "/master",
                no: 5,
            },
            {
                name: "お知らせ管理",
                icon: "mdi-information",
                link: "/information",
                no: 6,
            },
            {
                name: "DL資料管理",
                icon: "mdi-database",
                link: "/siryo",
                no: 25,
            },
            {
                name: "お問合せ",
                icon: "mdi-mail",
                link: "/inquiries",
                no: 30,
            },
        ],

    }),
    computed: {
        isLogin() {
            return this.$store.state.token == "" ? false : true;
        },
        isMenu: {
            get(){
                return this.$store.state.showMenu;
            },
            set(value){
                this.$store.commit('setShowMenu',{flg:value})
            }
        }
    },
    methods: {
        click_item() {},
    },
};
</script>
