export class CONSTANT {

	/********************
     * API戻り値
     ********************/
    /**  正常 */
    static RETURN_NORMAL = "0";
    /** データなし */
    static RETURN_NOT_FOUND= "1";
    /** DBワーニング */
    static RETURN_DB_WARNING= "2";
    /** 致命的エラー */
    static RETURN_INTERNAL_ERROR= "9";



	/********************
     * 稼働ステータス 
     ********************/
    /**  稼働中 */
    static KADO_STATUS_KADO = "0";
    /**  停止 */
    static KADO_STATUS_TEISHI = "1";
    /**  時間指定 */
    static KADO_STATUS_JIKAN = "2";


	/********************
     * 処理モード 
     ********************/
    static MODE_UPDATE = 'U';
    static MODE_ADD = 'A';
    static MODE_DEL= 'D';



}