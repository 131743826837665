<template>
    <div>
        <v-col cols="12" class="pt-2 pl-2 pb-2">
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="inputDate"
                    dense
                    :clearable="isClearBtn"
                    outlined
                    readonly
                    autocomplete="off"
                    :disabled="disabled"
                    v-model="compDate"
                    hide-details="auto"
                    :error="error"
                    persistent-hint
                    v-bind="attrs"
                    prepend-icon="mdi-calendar"                    
                    v-on="on"
                    @click:clear="compDate=null"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="compDate" locale="jp-ja" :day-format="date => new Date(date).getDate()" no-title @input="menu = false" :type="dateType"></v-date-picker>
            </v-menu>
        </v-col>
    </div>
</template>
<style lang="scss">
.inputfld {
    width: 200px;
}
.v-date-picker-table {
    table,
    th,
    tr,
    td {
        border: 0px none !important;
    }
}
.msg {
    width: 400px;
}
.inputDate {
    width: 180px;
}
</style>

<script>
import moment from "moment"
export default {
    props: {
        dateType: {
            type: String,
            default: "date",
        },
        date: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        isClearBtn:{
            type:Boolean,
            default:true
        }
    },
    computed: {
        isClearable:{
            get(){
                if(this.disabled || !this.isClearBtn){
                    return false;
                }
                return true;
            }
        },
        compDate: {
            get() {
                if(this.date && moment(this.date).isValid){
                    return this.date.substr(0,10);
                }
                else{
                    return null;
                }
            },
            set(value) {
                this.$emit("update:date", value);
            },
        },
    },
    methods: {
        clickOk() {
            this.$refs.menu.save(this.compDate);
        },
    },
    data: () => ({
        pDate: null,
        menu: false,
        modal: false,
        menu2: false,
    }),
};
</script>