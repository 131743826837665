<template>
    <v-dialog v-model="dialog" persistent max-width="1100px">
        <v-toolbar color="teal" height="40" dark>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
            <v-spacer />
        </v-toolbar>
        <v-card tile class="pa-5">
            <v-row dense>
            <v-col cols="6">
                <div class="print-table-title">依頼先</div>
                <div class="mx-5" elevation="0">
                    <v-simple-table
                        dense
                        class="pa-2 mr-0 sms-list-table line"
                        height="auto"
                        fixed-header
                    >
                        <col style="width: 150px" />
                        <col style="width: auto" />
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="text-left">発注番号</th>
                                <td>{{hatchuDetail.hatchu_no}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">依頼先 会社ID</th>
                                <td>{{hatchuDetail.member_id}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">依頼先 会社名</th>
                                <td>{{hatchuDetail.corp_name}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">依頼先 担当者</th>
                                <td>{{hatchuDetail.tantou_name}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">依頼先 住所</th>
                                <td>〒{{hatchuDetail.address}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">依頼先 電話番号</th>
                                <td>{{hatchuDetail.tel}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <div class="mx-5" elevation="0">
                    <v-simple-table
                        dense
                        class="pa-2 mr-0 sms-list-table line"
                        height="auto"
                        fixed-header
                    >
                        <col style="width: 100px" />
                        <col style="width: 100px" />
                        <col style="width: 100px" />
                        <col style="width: 100px" />
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>印刷依頼日</th>
                                <td>{{ hatchuDetail.hikitori_date }}</td>
                                <th>発送予定日</th>
                                <td>{{ hatchuDetail.hassoyotei_date }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
            <v-col cols="6">
                <div class="print-table-title">発送先</div>
                <div class="mx-5" elevation="0">
                    <v-simple-table
                        dense
                        class="pa-2 mr-0 sms-list-table line"
                        height="auto"
                        fixed-header
                    >
                        <col style="width: 170px" />
                        <col style="width: auto" />
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th class="text-left">発送先 会社名</th>
                                <td>{{hatchuDetail.saki_corp_name}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">発送先 部署担当者</th>
                                <td>{{hatchuDetail.saki_tantou_busho}} {{hatchuDetail.saki_tantou_name}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">発送先 住所</th>
                                <td>〒{{hatchuDetail.saki_post}} {{hatchuDetail.saki_address}}</td>
                            </tr>
                            <tr>
                                <th class="text-left">発送先 電話番号</th>
                                <td>{{hatchuDetail.saki_tel}}</td>
                            </tr>
                            <tr>
                                <th>メールアドレス</th>
                                <td><div v-for="(mail, index) in mailList" :key="index">{{ mail }}</div></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <div class="mx-5" elevation="0">
                    <v-simple-table
                        dense
                        class="pa-2 mr-0 sms-list-table line"
                        height="auto"
                        fixed-header
                    >
                        <col style="width: 150px" />
                        <col style="width: auto" />
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>品目</th>
                                <th>数量</th>
                                <th>金額</th>
                            </tr>
                            <tr v-for="(item, index) in syukei" :key="index">
                                <td>{{ item.item_name }}</td>
                                <td class="text-right">{{ item.amount }}</td>
                                <td class="text-right">{{ numberFormat(item.gokei) }}</td>
                            </tr>
                            <tr>
                                <td>送料</td>
                                <td class="text-right">1</td>
                                <td class="text-right">{{ numberFormat(hatchuDetail.soryo) }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
            </v-row>
            <div class="print-table-title">注文内容</div>
            <div class="mx-5">
                <v-simple-table
                    dense
                    class="pa-2 mr-0 sms-list-table line tbl-max-height"
                    fixed-header
                >
                    <col style="width: 180px" />
                    <col style="width: 120px" />
                    <col style="width: auto" />
                    <col style="width: 160px" />
                    <col style="width: 150px" />
                    <col style="width: 70px" />
                    <thead>
                        <tr>
                            <th>注文番号</th>
                            <th>コンテナCD</th>
                            <th>コンテナ名</th>
                            <th>コンテナ識別NO</th>
                            <th>用紙タイプ</th>
                            <th>数量</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in chumonDetail" :key="index">
                            <td>{{ item.chumon_no }}</td>
                            <td>{{ contCodeFormat(item.cont_cd) }}</td>
                            <td>{{ item.type_name }}</td>
                            <td>{{ item.cont_uq_cd }}</td>
                            <td>{{ item.item_name }}</td>
                            <td class="text-right">{{ item.quantity }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
            <div class="print-table-title">備考欄</div>
            <div class="mx-5">
                <v-textarea
                    v-model="hatchuDetail.bikou"
                    readonly
                    class="ma-2"
                    maxlength="400"
                    background-color="white"
                    hide-details="auto"
                    outlined
                ></v-textarea>
            </div>
            <div class="ma-5 text-center" elevation="0">
                <v-btn class="btn-close ma-2" @click="clickClose()"> 閉じる </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<style scoped>
.tbl-max-height >>> .v-data-table__wrapper{
    max-height: 400px;
}
</style>
<script>
import { mapState } from "vuex";
import {MESSAGE} from '../../messages'

export default {
    components: {},
    computed: {
    },
    data: () => ({
        title: '発注詳細',
        dialog: false,
        hatchuNo: '',
        hatchuDetail: {},
        chumonDetail: {},
        syukei:[],
        mailList: [],
    }),
    mounted() {
    },
    watch: {
        dialog() {
            if(this.dialog) {
                this.$nextTick(() => {
                    const elements = document.getElementsByClassName('v-dialog--active');
                    elements[0].scrollTop = 0;
                })
            }
        }
    },
    methods: {
        /**
         * ダイアログのオープン
         */
        async open(hatchuNo) {
            this.hatchuNo = hatchuNo;
            await this.getHatchuInf();
            this.dialog = true;

            this.mailPipeToList(this.hatchuDetail.saki_mail)
        },
        /**
         * 発注詳細情報の取得
         */
        async getHatchuInf() {
            await this.axios.get(`api/admin-hatchu-detail/${this.hatchuNo}`).then((res) => {
                this.hatchuDetail = res.data.resultData.hatchuDetail[0];
                this.chumonDetail = res.data.resultData.hatchuDetail;
                this.syukei = res.data.resultData.syukei;
            });
        },
        /**
         * 閉じるボタンクリック
         */
        clickClose() {
            this.dialog = false;
        },
        /**
         * 入力チェック
         */

         /**
          * メールアドレスを改行
          */
        mailPipeToList(values) {
            console.log(values)
            this.mailList = values.split('|');
        },
    },
};
</script>
<style scope>
.print-table-title{
    display: inline-block;
    border-bottom: 1px solid #000;
    width: 70%;
    margin: 15px 20px !important;
    font-weight: bold;
}
</style>