<template>
    <v-card width="400px" class="mx-auto mt-5">
        <v-card-title>
            <h1 class="display-1">Login</h1>
        </v-card-title>
        <v-card-text>
            <v-form>
                <v-text-field
                    v-model="userId"
                    :error="isError(err.userId)"
                    prepend-icon="mdi-account-circle"
                    label="ユーザ名"
                    v-on:keyup.enter="login"
                    id="name"
                />
                <div class="controll-error ml-2" v-show="isError(err.userId)">
                    <span v-html="dispError(err.userId)"></span>
                </div>
                <v-text-field
                    v-model="passWord"
                    maxlength="15"
                    :error="isError(err.passWord)"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    label="パスワード"
                    v-on:keyup.enter="login"
                    id="password"
                />
                <div class="controll-error ml-2" v-show="isError(err.passWord)">
                    <span v-html="dispError(err.passWord)"></span>
                </div>
                <v-card-actions>
                    <v-btn class="info" @click="login">ログイン</v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
import { MESSAGE } from "../messages";
export default {
    data: () => ({
        showPassword: false,
        getUserMessage: "",
        userId: "",
        passWord: "",

        //エラー表示用
        err: ({} = {
            userId: [],
            passWord: [],
        }),
    }),
    created() {},
    methods: {
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        /**
         * エラーチェック
         */
        async inputCheck() {
            if (this.userId === "") {
                this.err.userId = [
                    MESSAGE.formatByArr(MESSAGE.E001, "ユーザーID"),
                ];
            }
            if (this.passWord === "") {
                this.err.passWord = [
                    MESSAGE.formatByArr(MESSAGE.E001, "パスワード"),
                ];
            }
            for (var key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        /**
         * ログイン
         */
        async login() {
            this.clearError();

            if (await this.inputCheck()) {
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            axios
                .post("/api/admlogin", {
                    userid: this.userId,
                    password: this.passWord,
                })
                .then((res) => {
                    if (res.data.result == "0") {
                        this.$store.commit("setToken", res.data.token);
                        this.$router.push("/");
                    } else {
                        this.swalAlert(
                            MESSAGE.TITLE_ERROR,
                            MESSAGE.formatByArr(
                                MESSAGE.E005,
                                "ログインID・パスワード"
                            )
                        );
                    }
                });
        },
        /**
         * ログアウト
         */
        logout() {
            axios
                .get("/api/admlogout")
                .then((res) => {
                    this.user = {};
                    this.isLogin = false;
                })
                .catch((err) => {
                });
        },
    },
};
</script>