<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >お知らせ管理</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mx-auto">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                   詳細 
                </v-card-subtitle>
                <v-spacer />
                <v-btn large class="ma-2 btn-edit" @click="clickSave"
                    >保存</v-btn
                >
                <v-btn large class="ma-2 btn-close" @click="clickBack"
                    >もどる</v-btn
                >
            </v-system-bar>
        </v-card>
        <v-card class="mx-auto">
            <v-simple-table class="sms-list-table mt-4 pt-4 pr-4 pl-4 pb-4">
                <col style="width: 200px" />
                <tr>
                    <th>ID</th>
                    <td>
                        <template v-if="mode == 'i'">
                            <div class="my-3 ml-3">
                                IDは登録時に自動発番されます
                            </div>
                        </template>
                        <template v-else>
                            <div class="kashi-no text-center">
                                <div class="sms-label ma-2">{{ inp.id }}</div>
                            </div>
                        </template>
                    </td>
                </tr>
                <tr>
                    <th>表示日付</th>
                    <td>
                        <input-date-fld :date.sync="inp.dispDate" :error="isError(err.dispDate)" />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.dispDate)"
                        >
                            <span v-html="dispError(err.dispDate)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>表示・非表示</th>
                    <td>
                        <v-radio-group class="ml-3" v-model="inp.dispFlg" row>
                            <v-radio
                                v-for="list in jyotai"
                                :key="list.val"
                                :label="list.kbn_name"
                                :value="list.val"
                            />
                        </v-radio-group>
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.dispFlg)"
                        >
                            <span v-html="dispError(err.dispFlg)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>タイトル</th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="80"
                            :error="isError(err.title)"
                            outlined
                            hide-details=""
                            dense
                            class="ma-2 information-title"
                            v-model="inp.title"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.title)"
                        >
                            <span v-html="dispError(err.title)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>詳細内容</th>
                    <td>
                        <v-textarea
                            solo
                            maxlength="1000"
                            outlined
                            :error="isError(err.content)"
                            hide-details="auto"
                            dense
                            class="ma-2"
                            v-model="inp.content"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.content)"
                        >
                            <span v-html="dispError(err.content)"></span>
                        </div>
                    </td>
                </tr>
            </v-simple-table>
        </v-card>
    </v-container>
</template>
<style scope>
.title {
    width: 110px;
}
.input-control {
    width: 100%;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px !important;
}
</style>
<script>
import { MESSAGE } from "../messages";
import InputDateFld from "../components/InputDateFld";
export default {
    name: "Member",
    components: {InputDateFld},
    data() {
        return {
            mode: "i",
            infoId: "",
            inp: {
                id: "",
                dispDate: "",
                title: "",
                content: "",
                dispFlg: "",
            },

            //エラー表示用
            err: ({} = {
                id: [],
                dispDate: [],
                title: [],
                content: [],
                dispFlg: [],
            }),
            jyotai: ([] = [
                { val: 1, kbn_name: "表示する" },
                { val: 0, kbn_name: "表示しない" },
            ]),
        };
    },
    mounted() {
        this.infoId = this.$route.params.id;
        if (this.infoId) {
            this.mode = "u";
            this.getInformation();
        } else {
            this.mode = "i";
        }
        this.clearError();
    },
    methods: {
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        /**
         * お知らせを取得
         */
        getInformation() {
            this.axios.get(`/api/information/${this.infoId}`).then((res) => {
                const info = res.data.information;
                this.inp.id = info.id;
                this.inp.dispDate = this.dateFormat(info.disp_date);
                this.inp.title = info.title;
                this.inp.content = info.content;
                this.inp.dispFlg = info.disp_flg;
            });
        },
        /**
         * エラーチェック
         */
        async inputCheck(){
            //-- 表示日付
            if (!this.inp.dispDate) {
                this.err.dispDate = [
                    MESSAGE.formatByArr(MESSAGE.E001, "表示日付"),
                ];
            }

            //-- 表示・非表示
            if (this.inp.dispFlg === '') {
                this.err.dispFlg = [
                    MESSAGE.formatByArr(MESSAGE.E002, "表示・非表示"),
                ];
            } 

            //-- タイトル 
            if (!this.inp.title) {
                this.err.title = [
                    MESSAGE.formatByArr(MESSAGE.E001, "タイトル"),
                ];
            } 

            //-- 詳細内容
            if (!this.inp.content) {
                this.err.content = [
                    MESSAGE.formatByArr(MESSAGE.E001, "詳細内容"),
                ];
            } 
            for (var key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        /**
         * 登録
         */
        async clickSave() {

            this.clearError();

            // エラーチェック
            var result = await this.inputCheck();
            if (result) {
                this.saveLoading = null;

                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            var res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                this.mode == "i" ? MESSAGE.R001 : MESSAGE.R002
            );
            if (!res) {
                this.saveLoading = null;
                return;
            }
            if (this.mode === "i") {
                /** 新規登録処理 */
                // storeメソッド
                this.axios.post("/api/information", this.inp).then((res) => {
                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,`お知らせ情報`)  );
                    //正常終了
                    this.$router.push("/information");
                });
            } else {
                /** 更新処理 */
                // updateメソッド
                this.axios
                    .put(`/api/information/${this.inp.id}`, this.inp)
                    .then((res) => {
                        this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,`お知らせ情報`)  );
                        this.$router.push("/information");
                    });
            }

        },
        /**
        * もどるボタン処理 
         */
        clickBack() {
            this.$router.push("/information");
        },
    },
};
</script>

