<template>
  <div class="about">
    <div class="d-inline-flex">
     <input-date-fld :date.sync="yymmdd" ></input-date-fld>
     <div class="ma-2">
    <vue-timepicker v-model="hhmm" class="test" format="HH:mm"></vue-timepicker>
     </div>
    </div>
    {{yymmdd}} {{hhmm}}
  </div>
</template>
<style lang="scss">
.test{
  input {
    border:1px solid #aaa !important;
    border-radius: 5px !important;
    padding:19px 10px !important;
  }
  .clear-btn{
    padding:23px 6px !important;
    .char{
      font-size:18pt !important;
      color: rgba(0, 0, 0, 0.54) !important;
    }

  }
}
</style>
<script>

//time picker
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import InputDateFld from "../components/InputDateFld.vue"
export default {
    name: "Member",
    components: {VueTimepicker, InputDateFld},
    data() {
        return {
          hhmm:'12:05',
          yymmdd:'2021-05-05'
        }

    }
}
</script>