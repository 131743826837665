<template>
    <v-container fluid class="mt-0 mb-3 pa-0">
        <v-card class="page-menu mb-3" rounded="0" flat>
            <v-sheet elevation="0" height="20"></v-sheet>
            <v-card class="mx-3">
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                    会員 
                    </v-card-subtitle>
                    <v-spacer />
                    <v-btn large class="ma-2 btn-edit" @click="clickSave"
                        >保存</v-btn
                    >
                    <v-btn large class="ma-2 btn-close" @click="clickBack"
                        >もどる</v-btn
                    >
                </v-system-bar>
            </v-card>
        </v-card>
        <v-card class="mx-3">
            <v-simple-table class="rentimput mt-4 pt-4 pr-4 pl-4 pb-4">
                <tr>
                    <th class="text-left pl-3">会員ID</th>
                    <td>
                        <template v-if="mode=='i'">
                            <div class="my-3 ml-3">会員IDは登録時に自動発番されます</div>
                        </template>
                        <template v-else>
                            <div class="sms-label ma-2">{{memberId}}</div>
                        </template>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">申込日<span class="required">(必須)</span></th>
                    <td>
                        <input-date-fld :date.sync="inp.appDate"
                            :error="isError(err.appDate)"
                         ></input-date-fld>
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.appDate)"
                            >
                                <span v-html="dispError(err.appDate)"></span>
                            </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">状態<span class="required">(必須)</span></th>
                    <td>
                        <v-radio-group class="pl-2 pb-3 pt-0" v-model="inp.statusFlg" hide-details="" dense row>
                            <v-radio dense
                                v-for="list in jyotai"
                                :key="list.val"
                                :label="list.kbn_name"
                                :value="list.val"
                            />
                        </v-radio-group>
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.statusFlg)"
                        >
                            <span v-html="dispError(err.statusFlg)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">会員CD<span class="required">(必須)</span></th>
                    <td>
                            <v-text-field
                                solo
                                maxlength="10"
                                type="tel"
                                outlined
                                :error="isError(err.memberCd)"
                                hide-details="auto"
                                dense
                                class="ma-2 tokui-cd"
                                v-model="inp.memberCd"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.memberCd)"
                            >
                                <span v-html="dispError(err.memberCd)"></span>
                            </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">会社名<span class="required">(必須)</span></th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="40"
                            outlined
                            :error="isError(err.corpName)"
                            hide-details="auto"
                            dense
                            class="ma-2 tokui-name"
                            v-model="inp.corpName"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.corpName)"
                        >
                            <span v-html="dispError(err.corpName)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">会社名（カナ）</th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="40"
                            outlined
                            :error="isError(err.corpKana)"
                            hide-details=""
                            dense
                            class="ma-2 tokui-kana"
                            v-model="inp.corpKana"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.corpKana)"
                        >
                            <span v-html="dispError(err.corpKana)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">代表者名<span class="required">(必須)</span></th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="20"
                            outlined
                            :error="isError(err.memberName)"
                            hide-details=""
                            dense
                            class="ma-2 tantou-name"
                            v-model="inp.memberName"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.memberName)"
                        >
                            <span v-html="dispError(err.memberName)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">代表者名（カナ）</th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="20"
                            outlined
                            :error="isError(err.memberKana)"
                            hide-details=""
                            dense
                            class="ma-2 tantou-name"
                            v-model="inp.memberKana"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.memberKana)"
                        >
                            <span v-html="dispError(err.memberKana)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">担当者名</th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="20"
                            outlined
                            hide-details=""
                            dense
                            class="ma-2 tantou-name"
                            v-model="inp.tantouName"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">郵便番号<span class="required">(必須)</span></th>
                    <td>
                        <div class="d-inline-flex">
                            <v-text-field
                                solo
                                type="tel"
                                maxlength="8"
                                outlined
                                hide-details="auto"
                                :error="isError(err.post)"
                                dense
                                class="ma-2 post"
                                v-model="inp.post"
                                @blur="blurPost"
                            />
                            <v-btn
                                class="ma-2 btn-edit"
                                @click="getAddress"
                                >住所変換</v-btn
                            >
                        </div>
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.post)"
                        >
                            <span v-html="dispError(err.post)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">緯度・経度</th>
                    <td>
                        <div class="d-inline-flex">
                            <div>
                                <v-text-field class="ma-2" maxlength="12" type="tel" label="緯度" v-model="inp.lat" :error="isError(err.lat)" solo outlined hide-details="" dense />
                                <div class="controll-error ml-2" v-show="isError(err.lat)">
                                    <span v-html="dispError(err.lat)"></span>
                                </div>
                            </div>
                            <div>
                                <v-text-field class="ma-2" maxlength="12" type="tel" label="経度" v-model="inp.lng" :error="isError(err.lng)" solo outlined hide-details="" dense />
                                <div class="controll-error ml-2" v-show="isError(err.lng)">
                                    <span v-html="dispError(err.lng)"></span>
                                </div>
                            </div>
                            <v-btn class="ma-2" @click="clickMap">地図表示</v-btn>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">住所１<span class="required">(必須)</span></th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="40"
                            outlined
                            :error="isError(err.address1)"
                            hide-details=""
                            dense
                            class="ma-2 address"
                            v-model="inp.address1"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.address1)"
                        >
                            <span v-html="dispError(err.address1)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">住所２</th>
                    <td>
                        <v-text-field
                            solo
                            maxlength="40"
                            outlined
                            hide-details=""
                            dense
                            class="ma-2 address"
                            v-model="inp.address2"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">電話番号<span class="required">(必須)</span></th>
                    <td>
                        <v-text-field
                            solo
                            type="tel"
                            maxlength="14"
                            :error="isError(err.tel)"
                            outlined
                            hide-details="auto"
                            dense
                            class="ma-2 tel"
                            v-model="inp.tel"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.tel)"
                        >
                            <span v-html="dispError(err.tel)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">FAX番号</th>
                    <td>
                        <v-text-field
                            solo
                            type="tel"
                            maxlength="14"
                            :error="isError(err.fax)"
                            outlined
                            hide-details=""
                            dense
                            class="ma-2 tel"
                            v-model="inp.fax"
                        />
                        <div
                            class="controll-error ml-2"
                            v-show="isError(err.fax)"
                        >
                            <span v-html="dispError(err.fax)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">メールアドレス<span class="required">(必須)</span></th>
                    <td>
                        
                            <div  class="" v-for="(mail, index) in inp.mail" :key="index">
                                <div class="d-flex d-inline-flex align-center ma-2">
                                        {{ index + 1 }}：
                                    <v-text-field
                                        solo
                                        v-model="inp.mail[index]"
                                        maxlength="40"
                                        outlined
                                        hide-details="auto"
                                        :error-messages="errMail(index)"
                                        dense
                                        class="mail"
                                        :error="isErrMail(index)"
                                    />
                                    <div class="d-flex flex-row-revers" v-if="index==0 && mode=='u'">
                                        <v-btn class="ma-2 btn-edit" @click="clickAppoval"
                                            >承認メール再送信
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                            <div v-show="inp.mail.length<10" class="ma-2">
                                <div @click="clickAddMail" class="mt-3 d-flex d-inline-flex">
                                    <v-icon color="blue">mdi-plus-circle-outline</v-icon>
                                        <div class="text-caption">メールアドレスを追加する </div>
                                    </div>
                            </div>
                    </td>
                </tr>
                <tr>
                    <th class="text-left pl-3">パスワード<span class="required">(必須)</span></th>
                    <td>
                        <div class="d-inline-flex">
                            <v-text-field
                                solo
                                :type="showPassword ? 'text' : 'password'"
                                maxlength="15"
                                :error="isError(err.password)"
                                outlined
                                hide-details=""
                                :append-icon="showPassword ?'mdi-eye': 'mdi-eye-off'" 
                                dense
                                class="ma-2"
                                style="width: 200px;"
                                v-model="inp.password"
                                @click:append="showPassword =! showPassword"
                            />
                            <div class="mt-2 text-subtitle-2">
                                パスワード強度: <span class="font-weight-bold">{{ meterVal }}</span>
                                <v-progress-linear
                                class="passMeter mt-2"
                                background-color="grey lighten-3"
                                :value="meter"
                                :color="meterColor"
                                height="8"
                                >
                                </v-progress-linear>
                            </div>
                            <ul class="passMeterCap mt-2 ml-2">
                                <li>8文字以上15文字以下の半角英数字で入力してください。</li>
                                <li>英大文字/英小文字/数字/記号(!#&)の中から、3種類以上を含むパスワードを設定してください。</li>
                                <li>パスワード強度が「弱い」または「やや弱い」の場合は、パスワードを登録できません。</li>
                            </ul>
                        </div>
                        <div
                            class="d-flex controll-error ml-2"
                            v-show="isError(err.password)"
                        >
                            <span v-html="dispError(err.password)"></span>
                        </div>
                    </td>
                </tr>
            </v-simple-table>
        </v-card>
        <dlg-map ref="refDlgMap" :dlg-call-back="callBackMap"/>
    </v-container>
</template>
<style lang="scss" scoped>
.rentimput {
    th {
        background-color: #ccc !important;
        width: 170px;
    }
    .sms-label {
        border-bottom: solid 1px #ccc;
    }
}
.passMeter{
    width:240px;
}
.passMeterCap{
    line-height:1.4;
    font-size:10px;
}
</style>
<script>
import { MESSAGE } from "../messages";
import DlgMap from "../components/dialog/DlgMap"
import InputDateFld from "../components/InputDateFld.vue"
export default {
    components: { DlgMap, InputDateFld },
    data() {
        return {
            mode: "i", //i:新規 u:更新
            memberId: "",
            saveLoading: false,
            showPassword : false,
            memberCdDef: '',

            //入力v-model
            inp:{
                memberId:'',
                memberCd:'',
                password:'',
                corpName:'',
                corpKana:'',
                memberName:'',
                memberKana:'',
                tantouName:'',
                post:'',
                lat:'',
                lng:'',
                address1:'',
                address2:'',
                tel:'',
                fax:'',
                mail:'',
                statusFlg:'',
                comment:'',
                appDate:'',
            },

            //エラー表示用
            err: ({} = {
                memberId:[],
                memberCd:[],
                password:[],
                corpName:[],
                corpKana:[],
                memberName:[],
                memberKana:[],
                tantouName:[],
                post:[],
                lat:[],
                lng:[],
                address1:[],
                address2:[],
                tel:[],
                fax:[],
                mail:[],
                statusFlg:[],
                comment:[],
                appDate:[],
            }),
            meterVal: '',
            meterColor: '',
            meterPoint: '',
        };
    },
    mounted(){
        this.memberId = this.$route.params.memberId;
        if(this.memberId!==''){
            // 更新モードセット
            this.mode = 'u';
            // 会員情報の取得
            this.getMember();
        }else{
            // 追加モードセット
            this.mode = 'i'
            this.inp.mail = [''];
            this.err.mail = [''];
        }
    },
    computed:{
        jyotai(){
            let jyotaiArr = [];
            if(this.mode === 'i'){
                jyotaiArr = [
                    { val: 1, kbn_name: "新規申込" },
                    { val: 2, kbn_name: "稼働中" },
                    { val: 3, kbn_name: "停止中" },
                    { val: 4, kbn_name: "保留" },
                    { val: 9, kbn_name: "退会" },
                    { val: 8, kbn_name: "試用中" },
                ];
            }else{
                jyotaiArr = [
                    { val: 2, kbn_name: "稼働中" },
                    { val: 3, kbn_name: "停止中" },
                    { val: 4, kbn_name: "保留" },
                    { val: 9, kbn_name: "退会" },
                    { val: 8, kbn_name: "試用中" },
                ];
            }
            return jyotaiArr;
        },
        /**
         * パスワード強度チェック
         */
        meter(){
            let mt = 0;
            this.meterPoint = 0;
            this.meterColor = '';
            this.meterVal = '';

            mt += /.{8,}/.test(this.inp.password) ? 40 : 0
            mt += /\d/.test(this.inp.password)    ? 15 : 0
            mt += /[a-z]/.test(this.inp.password) ? 15 : 0
            mt += /[A-Z]/.test(this.inp.password) ? 15 : 0
            mt += /[#!&]/.test(this.inp.password) ? 15 : 0

            if(1 <= mt){
                this.meterPoint = 25
                this.meterColor = '#FF0000'
                this.meterVal = '弱い'
            }
            if(60 <= mt){
                this.meterPoint = 50
                this.meterColor = 'orange'
                this.meterVal = 'やや弱い'
            }
            if(80 <= mt){
                this.meterPoint = 75
                this.meterColor = 'lime'
                this.meterVal = 'やや強い'
            }
            if(100 == mt){
                this.meterPoint = 100
                this.meterColor = 'green'
                this.meterVal = '強い'
            }

            return this.meterPoint;
        },
        errMail(){
            return (no)=>{
                return this.err.mail[no];
            }
        },
        isErrMail(){
            return (no)=>{
                return this.err.mail[no].length>0?true:false;
            }
        }, 
    },
    methods:{
        getMember(){
                this.axios.get(`/api/member/${this.memberId}`).then((res) => {
                    if(res.data.result!=0){
                        return ;
                    }
                    const memberInf = res.data.memberInf;
                    this.inp.memberId = memberInf.member_id;
                    this.inp.memberCd = this.memberCdDef = memberInf.member_cd;
                    this.inp.password = memberInf.password;
                    this.inp.corpName = memberInf.corp_name;
                    this.inp.corpKana = memberInf.corp_kana;
                    this.inp.memberName = memberInf.member_name;
                    this.inp.memberKana = memberInf.member_kana;
                    this.inp.tantouName = memberInf.tantou_name;
                    this.inp.post = memberInf.post;
                    this.inp.lat = memberInf.lat;
                    this.inp.lng = memberInf.lng;
                    this.inp.address1 = memberInf.address1;
                    this.inp.address2 = memberInf.address2;
                    this.inp.tel = memberInf.tel;
                    this.inp.fax = memberInf.fax;
                    this.inp.mail = memberInf.mail;
                    this.inp.statusFlg = memberInf.status_flg;
                    this.inp.comment = memberInf.comment;
                    this.inp.appDate = memberInf.app_date;
                    this.err.mail=[];
                    this.inp.mail.forEach(e=>{
                        this.err.mail.push('');
                    });
            }); 
        },
        /**
         * メールアドレスの追加
         */
        clickAddMail(){
            this.inp.mail.push('');
            this.err.mail.push('');
        },
        /**
         * 郵便番号庵フォーカス
         * ハイフンを付与する
         */
        blurPost() {
            if (this.isPostCode(this.inp.post)) {
                if (this.inp.post.length === 7) {
                    this.inp.post =
                        this.inp.post.slice(0, 3) + "-" + this.inp.post.slice(3);
                }
            }
        },
        /**
         * 郵便番号
         */
        getAddress(){
            //エラーのクリア
            this.err.post = [];
            if(this.inp.post===''){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001, "郵便番号")];
                return;
            }

            if( !this.isPostCode(this.inp.post)){
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E005, "郵便番号の形式")];
                return ;
            }
            this.axios
                .post(`/api/get-admin-address`, { post: this.inp.post })
                .then((res) => {
                    if (res.data.result == 0) {
                        this.inp.address1 = res.data.address;
                    }else{
                        this.err.post = [MESSAGE.formatByArr(MESSAGE.E009, "郵便番号","住所")];
                    }
                });

        },
        /**
         * 入力チェック
         */
        async inputCheck(){

          
            // -- 申込日時
            if(!this.inp.appDate){
                this.err.appDate = [MESSAGE.formatByArr(MESSAGE.E001,'申込日')];
            }

            // -- 状態
            if(this.inp.statusFlg === ''){
                this.err.statusFlg = [MESSAGE.formatByArr(MESSAGE.E002,'状態')];
            }

            //-- 会員CD
            if (!this.inp.memberCd) {
                this.err.memberCd = [MESSAGE.formatByArr(MESSAGE.E001,'会員CD')];
            }else{
                if(!this.isHanEisu(this.inp.memberCd)){
                    this.err.memberCd = [MESSAGE.E003];
                }
                if(this.inp.memberCd !== this.memberCdDef){
                    if(await this.isMemberCd()){
                        this.err.memberCd = [MESSAGE.formatByArr(MESSAGE.E010,'会員CD')];
                    }
                }
            }

            // -- 会社名
            if (!this.inp.corpName) {
                this.err.corpName = [MESSAGE.formatByArr(MESSAGE.E001,'会社名')];
            } 
            // -- 会社名（カナ）
            if (this.inp.corpKana && !this.isZenKana(this.inp.corpKana)) {
                this.err.corpKana = [MESSAGE.formatByArr(MESSAGE.E013,'全角カタカナ')];
            } 

            // -- 代表者名
            if (!this.inp.memberName) {
                this.err.memberName = [MESSAGE.formatByArr(MESSAGE.E001,'代表者名')];
            }
            // -- 代表者名（カナ）
            if (this.inp.memberKana && !this.isZenKana(this.inp.memberKana)) {
                this.err.memberKana = [MESSAGE.formatByArr(MESSAGE.E013,'全角カタカナ')];
            } 

            // -- 郵便番号
            if (!this.inp.post) {
                this.err.post = [MESSAGE.formatByArr(MESSAGE.E001,'郵便番号')];
            }else{
                if (!this.isPostCode(this.inp.post)) {
                    this.err.post = [MESSAGE.formatByArr(MESSAGE.E005,'郵便番号')];
                }
            }

            // -- 緯度・経度
            if (this.inp.lat && this.latlngFormat(this.inp.lat)) {
                this.err.lat = [MESSAGE.formatByArr(MESSAGE.E001,'正しい値')];
            }else{
                if(this.inp.lat && !this.inp.lng){
                    this.err.lng = [MESSAGE.formatByArr(MESSAGE.E001,'経度')];
                }
            }
            if (this.inp.lng && this.latlngFormat(this.inp.lng)) {
                this.err.lng = [MESSAGE.formatByArr(MESSAGE.E001,'正しい値')];
            }else{
                if(!this.inp.lat && this.inp.lng){
                    this.err.lat = [MESSAGE.formatByArr(MESSAGE.E001,'緯度')];
                }
            }

            // -- 住所１
            if (!this.inp.address1) {
                this.err.address1 = [MESSAGE.formatByArr(MESSAGE.E001,'住所')];
            }

            //*** 電話番号
            if (!this.inp.tel) {
                this.err.tel = [MESSAGE.formatByArr(MESSAGE.E001,'電話番号')];
            }else{
                if (!this.isTelNo(this.inp.tel)) {
                    this.err.tel = [MESSAGE.formatByArr(MESSAGE.E005, "電話番号")];
                }
            }

            //*** FAX番号
            if (this.inp.fax !== "" && !this.isTelNo(this.inp.fax)) {
                this.err.fax = [MESSAGE.formatByArr(MESSAGE.E005, "FAX番号")];
            }

            //*** メールアドレス
            for(let i=0; i< this.err.mail.length; i++){

                if(i==0){
                    if(!this.inp.mail[i]){
                        this.$set(this.err.mail,i, [ MESSAGE.formatByArr(MESSAGE.E001, `メールアドレス`), ]);
                        continue;
                    }
                }
                if (this.inp.mail[i]!== "" && !this.isMailaddress(this.inp.mail[i])) {
                    this.$set(this.err.mail, i, [
                        MESSAGE.formatByArr(MESSAGE.E005, "メールドレスの形式"),
                    ]);
                }
            }


            //*** パスワード
            if (!this.inp.password) {
                this.err.password = [MESSAGE.formatByArr(MESSAGE.E001, "パスワード")];
            }else{
                if(!this.isHanEisuKigo(this.inp.password) || this.meterPoint < 75){
                    this.err.password = [MESSAGE.formatByArr(MESSAGE.E001,'正しい値')];
                }
            }

            for (var key in this.err) {
                if(key==='mail'){
                    for(const key in this.err.mail){
                        if(this.err.mail[key].length>0) return true;
                    }
                }else{
                    if (this.err[key].length > 0) return true;
                }
            }

            return false;
        },
        async isMemberCd(){
            return await this.axios.get(`/api/member/is_member_cd/${this.inp.memberCd}`).then((res)=>{
                this.saveLoading = null;
                if(res.data.result != 0){
                    return true;
                }
                return false;
            });
        },
        async isMail(){
            let mailChk = {member_id: this.memberId, mail: this.inp.mail};
            return await this.axios.post(`/api/member-check/is_mail`, mailChk).then((res) => {
                if (res.data.result == 1) {
                    return true;
                }
                return false;
            });
        },
        /**
         * 保存ボタンクリック
         */
        async clickSave(){

            //error clear
            this.clearError();

            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            var res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                (this.mode=='i')?MESSAGE.R001:MESSAGE.R002);
            if(!res){
                this.saveLoading = null;
                return ;
            }
            if(this.mode==='i'){
                // store Api
                this.axios.post("/api/member", this.inp).then((res) => {
                    if (res.data.result != "0") {
                        this.saveLoading = null;
                        this.$router.go(-1);
                        return;
                    }

                    this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,this.inp.memberCd));
                    this.$router.go(-1);
                });
            }else{
                this.axios.put(`/api/member/${this.inp.memberId}`,this.inp).then((res)=>{
                            this.saveLoading = null;
                            this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,this.inp.memberCd));
                            this.$router.go(-1)
                });
            }

        },
        async clickBack(){
            this.$router.go(-1)
        },
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                if(key==='mail'){
                    for(let i=0; i<this.err.mail.length; i++){
                        this.$set(this.err.mail,i,[]);
                    }
                }else{
                    this.err[key] = [];
                }
            }
            
        },
        /**
         * 緯度経度マップ表示
         */
        clickMap(){
            var param = {
                lat:this.inp.lat,
                lng:this.inp.lng,
            }
            this.$refs.refDlgMap.open(param)
        },
        callBackMap(latlng){
            this.inp.lat = latlng.lat;
            this.inp.lng = latlng.lng;
        },
        /**
         * 承認メール再送信画面の表示
         */
        clickAppoval(){
            this.$store.commit('setMemberId',this.memberId);
            this.$router.push({name:"ApprovalEmail"});
        },
    }
}
</script>