<template>
    <v-footer color="teal" dark app v-if="isLogin">
      Footer SMSシステム・アドミン（仮称）Copy right .... 
    </v-footer>
</template>
<script>

export default {
    computed:{
        isLogin(){
            return this.$store.state.token==''?false:true;
        }
    }
}
</script>
