import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //ログイン成功時のトークン保存
    token:'',

    //メニューの表示・非表示を保存 true:表示
    showMenu:true,

    //会員ID
    memberId:'',
    memberPage:1,
    memberCorpName:"",
    memberCd:"",
    memberStatus:[],

    //得意先
    tokuisakiList:[],

    //現場
    genbaList:[],

    //コンテナ
    containerList:[],

    //コンテナタイプ
    containerTypeList:[],

    //用紙CD
    itemCd:'',
  },
  mutations: {
    //メニューの表示・非表示を切り替える
    chgShowMenu(state){
      state.showMenu = !state.showMenu ;
    },
    setShowMenu(state, payload){
      state.showMenu = payload.flg; 
    },
    setToken(state,token){
      state.token = token;
    },

    //会員ID
    setMemberId(state,memberId){
      state.memberId = memberId;
    },
    setMemberPage(state, memberPage)
    {
      state.memberPage = memberPage;
    },
    setMemberCd(state, memberCd)
    {
      state.memberCd=memberCd;
    },
    setMemberCorpName(state, memberCorpName)
    {
      state.memberCorpName=memberCorpName;
    },
    setMemberStatus(state, memberStatus)
    {
      state.memberStatus=memberStatus;
    },
    setTokuisakiList(state, tokuisakiList)
    {
      state.tokuisakiList = tokuisakiList;
    },
    setGenbaList(state, genbaList)
    {
      state.genbaList = genbaList;
    },
    setContainerList(state, containerList)
    {
      state.containerList = containerList;
    },
    setContainerTypeList(state, containerTypeList)
    {
      state.containerTypeList = containerTypeList;
    },
    setItemCd(state,itemCd){
      state.itemCd = itemCd;
    },

  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'vuex-session',
      storage: window.sessionStorage
    })
  ]
})
