<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >商品(用紙種別)マスタ</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-subheader class="text-h6 font-weight-black mt-3 mb-0"
            >商品(用紙種別)一覧
            <v-spacer />
            <v-btn color="deep-orange lighten-3 mr-6" @click="clickUpload()"
                >用紙サイズについて アップロード</v-btn
            >
            <v-btn color="deep-orange lighten-3" @click="clickAdd()"
                >商品(用紙種別)新規作成</v-btn
            >
        </v-subheader>
        <v-divider class="mb-1"></v-divider>
        <v-row>
            <v-col cols="8">
                <v-simple-table fixed-header height="auto" class="datatable" dense>
                    <template v-slot:default>
                        <col style="width: 100px" />
                        <col style="width: auto" />
                        <col style="width: 130px" />
                        <col style="width: 200px" />
                        <thead>
                            <tr>
                                <th>用紙CD</th>
                                <th>用紙名</th>
                                <th>表示区分</th>
                                <th>処理</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in printMst" :key="item.id" 
                            draggable 
                            @dragstart="draglist($event, index)" 
                            @drop="dorplist($event, index)"
                            @dragover.prevent
                            @dragenter.prevent
                            >
                                <td class="text-center">{{ item.item_cd }}</td>
                                <td>{{ item.item_name }}</td>
                                <td class="text-center" :class="[{'red--text':(item.disp_flg==0)},{'blue--text':(item.disp_flg==1)}]">
                                    {{ getJyotaiName(item.disp_flg) }}
                                </td>
                                <td class="text-center">
                                    <v-btn
                                        small
                                        class="ml-1 mr-1"
                                        color="light-blue lighten-4"
                                        :disabled="item.status_flg == 1"
                                        @click="clickAdd(item.item_cd)"
                                        >編集</v-btn
                                    >
                                    <v-btn
                                        small
                                        class="ml-1 mr-1"
                                        color="red lighten-4"
                                        @click="clickDelete(item.item_cd)"
                                        >削除</v-btn
                                    >
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>     
        </v-row>
        <dlg-upload-print-mst ref="refDlgUploadPrintMst" :dlg-call-back="uploadCallback"></dlg-upload-print-mst>
    </v-container>
</template>
<style scoped>
.title {
    width: 110px;
}
.input-control {
    width: 100%;
}
.datatable >>> table {
    border-collapse: collapse;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px !important;
}
</style>
<script>
import DlgUploadPrintMst from '../components/dialog/DlgUploadPrintMst.vue';
import { MESSAGE } from "../messages";
export default {
    name: "PrintMaster",
    components: { DlgUploadPrintMst },
    data() {
        return {
            printMst: [],
        };
    },
    mounted() {
        this.getprintMst();
    },
    methods: {
        /**
         * お知らせ一覧を取得
         */
        getprintMst() {
            this.axios.get("/api/admin-print-mst").then((res) => {
                this.printMst = res.data.resultData.printMst;
            });
        },
        /**
         * ドラッグイベント
         */
        async draglist(event, dragIndex){
            event.dataTransfer.effectAllowed = "move";
            event.dataTransfer.dropEffect = "move";
            event.dataTransfer.setData('drag-index', dragIndex);
            // console.log(dragIndex)
        },
        async dorplist(event, dorpIndex){
            const dragIndex = event.dataTransfer.getData('drag-index');
            const deletelist = this.printMst.splice(dragIndex, 1);
            this.printMst.splice(dorpIndex, 0, deletelist[0]);
            this.printMst.map((mstList, index) => {
                mstList.sortno = index + 1;
            });
            this.axios.post(`/api/update-print-mst-sortno`, {data: this.printMst})
        },
        /**
         * アップロードボタン
         */
        async clickUpload(){
            this.$refs.refDlgUploadPrintMst.open();
        },
        /**
         * 編集ボタン 
         */
        clickAdd(itemCd='') {
            this.$store.commit('setItemCd', itemCd);
            this.$router.push({name: "PrintMasterEdit"});
        },
        /**
         * 削除ボタン
         */
        async clickDelete(itemCd) {
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003,itemCd));

            // 「いいえ」の場合、処理しない
            if (!res) {
                return;
            }
            
            await this.axios.delete(`/api/admin-print-mst/${itemCd}`)
             .then((res) => {
                this.swalToast(MESSAGE.formatByArr(MESSAGE.I003,itemCd));
                this.getprintMst();
             });

        },
        getJyotaiName(kbn){
            let val = '';
            switch(kbn){
                case 0:
                    val = '表示しない';
                    break;
                case 1:
                    val = '表示する';
                    break;
                default:
            }

            return val;
        },
        uploadCallback() {
            this.getprintMst();
        }
    },
};
</script>
