<template>
<v-dialog v-model="dialog" persistent :max-width="900">
    <v-container fluid class="pa-0">
        <v-card class="ma-auto pb-5">
            <v-card class="mx-auto mb-2">
                <v-toolbar color="teal darken-3" height="40" dark>
                    <v-toolbar-title>お問合せ</v-toolbar-title>
                        <v-spacer />
                    <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-toolbar>
            </v-card>
            <v-card class="mx-10 mt-10 mb-5">
                <v-simple-table class="sms-list-table line">
                    <colgroup>
                        <col style="width:190px;" />
                    </colgroup>
                    <tr>
                        <th>No</th>
                        <td>
                            <div class="my-3 ml-3">{{inq_id}}</div>
                        </td>
                    </tr>
                    <tr>
                        <th>日付</th>
                        <td>
                            <div class="my-3 ml-3">{{inqInfo.inq_date}}</div>
                        </td>
                    </tr>
                    <tr>
                        <th>会員CD</th>
                        <td>
                            <div class="my-3 ml-3">
                                [{{inqInfo.member_id}}]&nbsp;{{ inqInfo.corp_name }}
                                </div>
                        </td>
                    </tr>
                    <tr>
                        <th>メール</th>
                        <td>
                            <div class="my-3 ml-3">
                                <div v-for="(item, index) in mails" :index="index">
                                    {{ item }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>区分</th>
                        <td>
                            <div class="my-3 ml-3">{{kubunName(inqInfo.inq_cd)}}</div>
                        </td>
                    </tr>
                    <tr>
                        <th>機種名</th>
                        <td>
                            <div v-html="inqInfo.model_name" class="my-3 ml-3"></div>
                        </td>
                    </tr>
                    <tr>
                        <th>内容</th>
                        <td>
                            <v-textarea
                            class="ma-0 pa-1"
                            hide-details="auto"
                            readonly
                            rows="3"
                            auto-grow
                            v-model="inqInfo.inq_text"
                            dense />
                        </td>
                    </tr>
                    <tr v-if="fileNameList.length>0">
                        <th>
                            添付ファイル
                        </th>
                        <td>
                            <div class="my-1 py-1 mx-0" v-for="(item, index) in fileNameList" :key="index">
                                <v-btn class="post-link" text @click="clickDownload(item.file_name)">{{item.file_name}}</v-btn>
                            </div>
                        </td>
                    </tr>
                </v-simple-table>
            </v-card>
            <v-card-actions>
                <v-btn class="mx-5 btn-close" @click="clickClose">閉じる</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
    </v-dialog>
</template>
<style lang="scss" scoped>
.post-link {
    color: #1976d2;
    display: inline-block;
    word-break: break-all;
    padding: 2px 8px;
    border-radius: 5px;
    text-transform: none !important;
    white-space: normal;
    max-width: 500px;
    height: inherit !important;
}
</style>
<script>
import { CONSTANT } from '../../constant';
export default {
    data() {
        return{
            dialog:false,
            inq_id:'',
            inqInfo:{},
            fileNameList: [],
            mails:[],
            }
    },
    methods:{
        detailsView(inqId){
            if(!inqId){
                return;
            }
            this.inq_id=inqId;
            this.getInqList();
            this.dialog = true;
        },
        async getInqList(){
            //show メソッド
            const res = await this.axios.post('/api/inquirie/inq-show',{inq_id:this.inq_id})
            this.inqInfo = res.data.resultData.inquirie;
            this.mails = [];
            const m = res.data.resultData.inquirie.mail.split(',');
            for (let i in m) {
                if (!m[i]) {
                    continue;
                }
                this.mails.push(m[i]);
            }
            this.fileNameList = res.data.resultData.flie_list;
        },
        kubunName(kubun) {
            let name = '';
            switch (kubun)
            {
                case 1:
                    name = "要望";
                    break;
                case 2:
                    name = "不具合";
                    break;
                case 3:
                    name = "その他";
                    break;
            }
            return name;
        },
        clickClose(){
            this.dialog = false;
        },
        async clickDownload(fileName){
            const res = await this.axios.post('/api/inquirie/inq-download',{inq_id: this.inq_id,file_name:fileName});
            if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                const blob = this.toBlob(res.data.resultData.file_data);
                // aタグを作成し、画面内でファイルダウンロードリンクをクリックするようにしている。
                var link = document.createElement('a');
                document.body.appendChild(link);
                link.href = URL.createObjectURL(blob);
                link.download = fileName; 
                link.click();
                document.body.removeChild(link);
            }
        },
    	toBlob(data64) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer]);
			} catch (e) {
				return false;
			}
			return blob;
    	},
    }

}
</script>