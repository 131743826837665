export class MESSAGE {
    static formatByArr(msg, ...args) {
        return msg.replace(/\{(\d+)\}/g, (m, k) => {
            return args[k];
        });
    }
    //----------- アイコン
    static ICON_SUCCESS = "success";
    static ICON_ERR = "error";
    static ICON_WARNING = "warning";
    static ICON_INFO = "info";
    static ICON_QUESTION = "question";

    //----------- タイトル
    static TITLE_CONFIRM = "確認";
    static TITLE_ERROR = "エラー";
    static TITLE_WARNING = "警告";

    //----------- 確認メッセージ
    static R001 = "登録してもよろしいですか。";
    static R002 = "更新してもよろしいですか。";
    static R003 = "{0} を削除してもよろしいですか。";
    static R004 = "{0} してもよろしいですか。";
    static R005 = "{0} 変更してもよろしいですか。";

    //----------- エラーメッセージ
    static E001 = "{0}を入力してください";
    static E002 = "{0}を選択してください";
    static E003 = "半角英数で入力してください";
    static E004 = "半角数値(0-9)で入力してください";
    static E005 = "{0}に誤りがあります";
    static E006 = "{0}は既に{1}で使用されているため削除できません";
    static E007 = "入力内容に誤りがあります、ご確認下さい";
    static E008 = "回収日に貸出日より以前の日付が入力されています";
    static E009 = "{0}に該当する{1}はありません";
    static E010 = "{0}は既に使用されているため登録できません";
    static E011 = "{0}は貸出中のコンテナがあるため削除できません";
    static E012 = "{0}は{1}で使用されているため削除できません";
    static E013 = "{0}で入力してください";
    static E014 = "料金表に誤りがあります、赤枠表示の項目について訂正してください";
    //------------ インフォーメーション
    static I001 = "{0}を登録しました";
    static I002 = "{0}を更新しました";
    static I003 = "{0}を削除しました";
    static I004 = "{0}しました";

}