<template>
    <v-container fluid class="mt-0 mb-3 pa-0">
        <div class="page-menu">
            <v-sheet elevation="0" height="20"></v-sheet>
            <v-card class="mx-3 mb-2">
                <v-system-bar color="teal darken-3" height="40">
                    <v-card-subtitle class="text-h6 font-weight-bold white--text"
                        >商品(用紙種別)マスタ</v-card-subtitle
                    >
                </v-system-bar>
            </v-card>
            <v-card class="mx-3">
                <v-system-bar color="gray" height="50">
                    <v-card-subtitle class="text-h6 font-weight-bold">
                    用紙種別登録 
                    </v-card-subtitle>
                    <v-spacer />
                    <v-btn large class="ma-2 btn-edit" @click="clickSave"
                        >保存</v-btn
                    >
                    <v-btn large class="ma-2 btn-close" @click="clickBack"
                        >もどる</v-btn
                    >
                </v-system-bar>
            </v-card>
        </div>
        <v-card class="mx-3">
            <v-simple-table class="print-table mt-4 pt-4 pr-4 pl-4 pb-4" style="width: 1400px;">
                <col style="width: 150px" />
                <col style="width: 400px" />
                <col style="width: 500px" />
                <tr>
                    <th>表示・非表示</th>
                    <td colspan="2">
                        <v-radio-group class="ml-3" v-model="inp.dispFlg" row>
                            <v-radio
                                v-for="list in jyotai"
                                :key="list.val"
                                :label="list.kbn_name"
                                :value="list.val"
                            />
                        </v-radio-group>
                    </td>
                </tr>
                <tr>
                    <th>用紙CD</th>
                    <td colspan="2">
                        <v-text-field
                            v-if="mode==='i'"
                            class="ma-2 item-cd"
                            v-model="inp.itemCd"
                            :autofocus="mode==='i'?true:false"
                            :error="isError(err.itemCd)"
                            dense
                            hide-details="auto"
                            outlined
                            maxlength="10"
                        >
                        </v-text-field>
                        <div v-else class="ma-3">
                            {{ inp.itemCd }}
                        </div>
                        <div class="controll-error ml-2" v-show="isError(err.itemCd)">
                            <span v-html="dispError(err.itemCd)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>用紙名称</th>
                    <td colspan="2">
                        <v-text-field
                            class="ma-2 hatchu-cd"
                            v-model="inp.itemName"
                            :autofocus="mode==='u'?true:false"
                            :error="isError(err.itemName)"
                            dense
                            hide-details="auto"
                            outlined
                            maxlength="30"
                        >
                        </v-text-field>
                        <div class="controll-error ml-2" v-show="isError(err.itemName)">
                            <span v-html="dispError(err.itemName)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>レイアウト</th>
                    <td>
                        <div v-for="category in layout" :key="category.no" class="ma-4">
                            <div class="layout-title">{{category.name}}</div>
                            <div v-for="(items, index) in category.data" :key="index" class="d-flex align-end">
                                <div v-for="item in items" :key="item.key">
                                    <span class="layout-sub-title">{{item.name}}</span>
                                    <v-text-field
                                        v-if="item.type == 0"
                                        v-model="item.val"
                                        @blur="item.val=toZero(item.val)"
                                        style="width: 80px;"
                                        class="ma-2"
                                        type="number"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        maxlength="10"
                                    >
                                    </v-text-field>
                                    <v-select
                                        v-else-if="item.type == 1"
                                        v-model="item.val"
                                        class="ma-2 post"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :items="item.list"
                                        item-text="name"
                                        item-value="val"
                                    />
                                    <v-text-field
                                        v-else
                                        v-model="item.val"
                                        class="ma-2"
                                        style="width: 150px;"
                                        dense
                                        hide-details="auto"
                                        outlined
                                        maxlength="30"
                                    >
                                    </v-text-field>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="d-flex align-start">
                        <div class="ml-10 mt-5">
                            <div class="font-weight-bold">印刷イメージ</div>
                            <div class="font-weight-bold"><v-btn color="blue" @click="review_pdf">
                                <v-icon left>
                                    mdi-autorenew
                                </v-icon>
                                再表示</v-btn></div>
                            <div class="mt-5">
                            <img :src="base64Image" />
                            </div>

                        </div>  
                    </td>
                </tr>
                <tr> <th>料金表</th>
                    <td colspan="2" class="pa-2">
                            <v-simple-table style="width:900px">
                                <colgroup>
                                    <col style="width: 100px" />
                                    <col style="width: 100px" />
                                    <col style="width: 150px" />
                                    <col style="width: 150px" />
                                    <col style="width: 150px" />
                                </colgroup>
                                <tr>
                                    <th>
                                        <div>セット数</div><div>（開始）</div>
                                    </th>
                                    <th>
                                        <div>セット数</div><div>（終了）</div>
                                    </th>
                                    <th>
                                        仕入単価 
                                    </th>
                                    <th>
                                        仕入税額
                                    </th>
                                    <th>
                                        販売価格 
                                    </th>
                                    <th>
                                        販売税額
                                    </th>
                                    <th>
                                        処理
                                    </th>
                                </tr>
                                <tr v-for="(item, index) in kingakuList">
                                    <td class="">
                                        <v-text-field outlined hide-details="auto"  v-model="item.from_qty" :error="err.kingaku[index].from_qty.length>0?true:false"  type="number" class="ma-2" style="width: 80px;" dense maxlength="4" />
                                    </td>
                                    <td class="">
                                        <v-text-field outlined hide-details="auto" v-model="item.to_qty" :error="err.kingaku[index].to_qty.length>0?true:false"  type="number" class="ma-2" style="width: 80px;" dense maxlength="4" />
                                    </td>
                                    <td>
                                        <v-text-field outlined hide-details="auto"  v-on:blur="setTaxRateSire(index)" v-model.number="item.kingaku" :error="err.kingaku[index].kingaku.length>0?true:false" type="number" class="ma-2" style="width: 120px;" dense maxlength="10" />
                                    </td>
                                    <td>
                                        <v-text-field outlined hide-details="auto" v-model="item.zeigaku" type="number" :error="err.kingaku[index].zeigaku.length>0?true:false"  class="ma-2" style="width: 120px;" dense maxlength="10"></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field outlined hide-details="auto" v-on:blur="setTaxRatehanabai(index)" v-model.number="item.hanbai_kingaku" :error="err.kingaku[index].hanbai_kingaku.length>0?true:false" type="number" class="ma-2" style="width: 120px;" dense maxlength="10" />
                                    </td>
                                    <td>
                                        <v-text-field outlined hide-details="auto" v-model="item.hanbai_zeigaku" :error="err.kingaku[index].hanbai_kingaku.length>0?true:false" type="number" class="ma-2" style="width: 120px;" dense maxlength="10"></v-text-field>
                                    </td>
                                    <td>
                                        <v-btn class="ml-3" color="red" @click="delKingaku(index)">削除</v-btn>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="text-center"> </td>
                                    <td>
                                        <v-btn class="ml-3 my-2" color="yellow" @click="addKingaku()">追加</v-btn>
                                    </td>
                                </tr>
                            </v-simple-table>  
                        </td>
                </tr>
            </v-simple-table>
        </v-card>
    </v-container>
</template>
<style lang="scss" scoped>
::v-deep .print-table {
    table,
    th,
    tr,
    td {
        border: solid 1px #ddd;
        border-collapse: collapse !important;
    }
    table {
        display: table;
        table-layout: collapse;
    }
    th {
        background-color: #eee !important;
        color: #555 !important;
        font-size: 12pt !important;
    }
}
.layout-title {
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #000;
    width: 200px;
}
.layout-sub-title {
    font-size: 12px;
}
</style>
<script>
import { MESSAGE } from "../messages";
export default {
    components: {  },
    data() {
        return {
            mode: '', // i:新規 u:更新
            title: '用紙種別登録', // ダイアログタイトル
            prevUrl: '', // PDF文字
            base64Image:'',
            prevFlg: null,
            // 入力v-model
            inp: ({} = {
                dispFlg: null,
                itemCd: '',
                itemName: '',
            }),
            // レイアウト用データ
            layout: [
                {
                    no:0,
                    name:'一般',
                    data:[
                        [
                            {key:'width', name:'横幅(mm)', val:null, type: 0,},
                            {key:'height', name:'高さ(mm)', val:null, type: 0,},
                        ],
                        [
                            {key:'margin1', name:'余白', val:null, type: 0,},
                            {key:'margin2', name:'', val:null, type: 0,},
                            {key:'margin3', name:'', val:null, type: 0,},
                        ],
                        [
                            {key:'item_type', name:'枠線', val:null, type: 1, list: [{val:0, name:'無'},{val:1, name:'有'}],},
                            {key:'font_family', name:'フォント', val:'', type: 1, list: [{val:'migu', name:'migu'}],},
                        ]
                    ]
                },
                {
                    no:1,
                    name:'文字1',
                    data:[
                        [
                            {key:'font_lg', name:'サイズ', val:null, type: 0,},
                        ],
                        [
                            {key:'text_position1', name:'位置', val:null, type: 0,},
                        ],
                        [
                            {key:'sample1', name:'サンプル', val:'', type: 2,},
                        ],
                    ]
                },
                {
                    no:2,
                    name:'文字2',
                    data:[
                        [
                            {key:'font_sm', name:'サイズ', val:null, type: 0,},
                        ],
                        [
                            {key:'text_position2', name:'位置', val:null, type: 0,},
                            {key:'text_position3', name:'', val:null, type: 0,},
                            {key:'text_position4', name:'', val:null, type: 0,},
                        ],
                        [
                            {key:'sample2', name:'サンプル', val:'', type: 2,},
                        ],
                    ]
                },
                {
                    no:3,
                    name:'注釈',
                    data:[
                        [
                            {key:'font_cap', name:'サイズ', val:null, type: 0,},
                        ],
                        [
                            {key:'cap_margin1', name:'余白', val:null, type: 0,},
                            {key:'cap_margin2', name:'', val:null, type: 0,},
                        ],
                        [
                            {key:'cap_position1', name:'位置', val:null, type: 0,},
                            {key:'cap_position2', name:'', val:null, type: 0,},
                        ],
                    ]
                },
            ],
            /*
            kingaku: [
                {key:0, name:'1～4セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:1, toQty:4},
                {key:1, name:'5～9セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:5, toQty:9},
                {key:2, name:'10～49セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:10, toQty:49},
                {key:3, name:'50～99セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:50, toQty:99},
                {key:4, name:'100～299セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:100, toQty:299},
                {key:5, name:'300～499セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:300, toQty:499},
                {key:6, name:'500～セット', kingakuName:'', kingaku:null,hanbai_kingaku:null, fromQty:500, toQty:9999},
            ],
            */
            jyotai: [
                { val: 1, kbn_name: "表示する" },
                { val: 0, kbn_name: "表示しない" },
            ],
            kingakuList:[],
            kingakuListOld:[],
            tax_rate: 0,
            decimal_kbn: 1,
            //エラーチェック用
            err: ({} = {
                itemCd: [],
                itemName: [],
                kingakuName: [],
                kingaku: [],
                hanbai_kingaku: [],
                zeigaku: [],
                hanbai_zeigaku: [],
            }),
        };
    },
    async mounted(){
        let itemCd = this.$store.state.itemCd;
        this.init();
        this.clearError();

        if(itemCd !== ''){
            // 編集モード
            this.mode = 'u';
            this.title = '用紙種別更新';
            await this.getData(itemCd);
        }else{
            // 新規モード
            this.mode = 'i';
            this.title = '用紙種別登録';
        }

        this.preview();

    },
    computed:{

    },
    methods:{
        // データ取得
        async getData(itemCd) {
            this.kingakuList = [];
            return await this.axios.get(`/api/admin-print-mst/${itemCd}`).then((res) => {
                for(let key in res.data.resultData.printMst){
                    //CD・名称取得
                    this.inp.itemCd = res.data.resultData.printMst.item_cd;
                    this.inp.itemName = res.data.resultData.printMst.item_name;
                    this.inp.dispFlg = res.data.resultData.printMst.disp_flg;

                    //QR用データ取得
                    this.pushLayout(key, res.data.resultData.printMst[key]);
                }
                this.kingakuList = res.data.resultData.printKingaku;
                this.kingakuListOld = JSON.parse(JSON.stringify(this.kingakuList));
                this.tax_rate = res.data.resultData.taxRate;
                this.decimal_kbn = res.data.resultData.decimal_kbn;       
                this.kingakuList.forEach(function (item) {
                    this.err.kingaku.push({from_qty:[], to_qty:[], kingaku:[], zeigaku:[], hanbai_kingaku:[],hanbai_zeigaku:[]});
                }, this)
            });
        },
        review_pdf() {
            this.preview()
        },
        //仕入れ税額
        setTaxRateSire(index) {
            if(this.kingakuListOld[index].kingaku === this.kingakuList[index].kingaku){
                return
            }
            let zeigaku = this.kingakuList[index].kingaku * this.tax_rate;
            if(isNaN(zeigaku)){
                this.kingakuList[index].zeigaku = '';
            }
            if(zeigaku === 0) {
                this.kingakuList[index].zeigaku = '';
            }
            else 
            {
                this.kingakuList[index].zeigaku = this.getDecimal(zeigaku);
            }
            this.kingakuListOld = JSON.parse(JSON.stringify(this.kingakuList));
        },
        //販売税額
        setTaxRatehanabai(index){
            if(this.kingakuListOld[index].hanbai_kingaku === this.kingakuList[index].hanbai_kingaku){
                return
            }
            let hanbai_zeigaku = this.kingakuList[index].hanbai_kingaku * this.tax_rate;
            if(isNaN(hanbai_zeigaku)){
                this.kingakuList[index].hanbai_zeigaku = 0;
            }
            if(hanbai_zeigaku === 0) {
                this.kingakuList[index].hanbai_zeigaku = '';
            }
            else 
            {
                this.kingakuList[index].hanbai_zeigaku = this.getDecimal(hanbai_zeigaku);
            }
            this.kingakuListOld = JSON.parse(JSON.stringify(this.kingakuList));
        },

        getDecimal(kingaku){
              // １切り上げ・２切り捨て・３四捨五入
            switch(this.decimal_kbn){
                case 1:
                    kingaku = Math.floor(kingaku * 10) / 10;
                    kingaku = Math.ceil(kingaku);
                    break;
                case 2:
                    kingaku = Math.floor(kingaku);
                    break;
                case 3:
                    kingaku = Math.round(kingaku);
                    break;
            }
            return kingaku;
        },
        async preview(active=''){
            // this.prevFlg = prevFlg;
            let prevData = {};
            //取得用データ格納
            prevData = this.listLayout();

            prevData['active'] = active;
            prevData['itemName'] = this.inp.itemName;
    
            const res = await this.axios.post('/api/admin-print-mst-preview2', prevData);
                //const base64 = new Buffer(response.data, "binary").toString("base64");
               // this.prevUrl = 'data:application/pdf;base64,' + base64;
                this.base64Image =  'data:image/jpeg;base64,' +  res.data.resultData.base64_image;
        },
        // 登録ボタン
        async clickSave(){
            //データ格納
            let layout = this.listLayout();
            for(let i in layout){
                this.inp[i] = layout[i];
            }

            this.inp['kingaku'] = this.kingakuList;

            // 入力チェック
            if(await this.inputCheck()){
                return;
            }

            // 保存確認アラート
            if(this.mode === 'i'){
                var res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R001))
            }else{
                var res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R002))
            }

            // 「いいえ」の場合、処理しない
            if( !res ){
                return;
            }

            if(this.mode === 'i'){
                /** 新規登録処理 **/
                await this.axios.post('/api/admin-print-mst', this.inp)
                .then((res) => {
                    this.dialog = false;
                    this.swalToast(MESSAGE.formatByArr(MESSAGE.I001,this.inp.itemCd));
                });
            }else if(this.mode === 'u'){
                /** 更新処理 **/
                await this.axios.put(`/api/admin-print-mst/${this.inp.itemCd}`, this.inp)
                .then((res) =>{
                    this.dialog = false;
                    this.swalToast(MESSAGE.formatByArr(MESSAGE.I002,this.inp.itemCd));
                });
            }

            this.$router.push("/printmst")
        },
         // 入力チェック 関数
        async inputCheck(){
            // エラーチェック初期化
            this.clearError();

            // 入力した値が空の場合エラー表示
            if(this.inp.itemCd === ''){
                this.err.itemCd = [MESSAGE.formatByArr(MESSAGE.E001,`用紙CD`)];
            //半角英数チェック
            }else if(!this.isHanEisu(this.inp.itemCd)) {
                this.err.itemCd = [MESSAGE.formatByArr(MESSAGE.E003)];
            }else{
                // 使用可能なCDかチェック
                if(this.mode === "i" && await this.isCdCheck()){
                    this.err.itemCd = [MESSAGE.formatByArr(MESSAGE.E010,`この用紙CD`)];
                }
            }

            if(this.inp.itemName === ''){
                this.err.itemName = [MESSAGE.formatByArr(MESSAGE.E001,`用紙名称`)];
            }

            let kingakuError = false;
            this.kingakuList.forEach((item, index) => {
                item.error = '';
                if (item.kingaku === '') {
                    this.err.kingaku[index].kingaku = [MESSAGE.formatByArr(MESSAGE.E001, `金額`)];
                    kingakuError = true;
                }
                if (item.zeigaku === '') {
                    this.err.kingaku[index].zeigaku = [MESSAGE.formatByArr(MESSAGE.E001, `金額`)];
                    kingakuError = true;
                }
                if (item.hanbai_kingaku === '') {
                    this.err.kingaku[index].hanbai_kingaku  = [MESSAGE.formatByArr(MESSAGE.E001, `金額`)];
                    kingakuError = true;
                }
                if (item.hanbai_zeigaku === '') {
                    this.err.kingaku[index].hanbai_zeigaku = [MESSAGE.formatByArr(MESSAGE.E001, `金額`)];
                    kingakuError = true;
                }
                if (item.from_qty === '') {
                    this.err.kingaku[index].from_qty  = [MESSAGE.formatByArr(MESSAGE.E001, `金額`)];
                    kingakuError = true;
                }
                if (item.to_qty === '') {
                    this.err.kingaku[index].to_qty  = [MESSAGE.formatByArr(MESSAGE.E001, `金額`)];
                    kingakuError = true;
                }
            }, this);

            if(kingakuError){
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E014);
                return true;
            }

            for (let key in this.err) {
                if(key === 'kingaku'){
                    continue;
                }
                if(this.err[key].length>0){
                    this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                    return true;
                }
            }

            return false;
        },
        // CD重複チェック
        async isCdCheck() {
            return await this.axios.get(`/api/admin-print-mst/is_item_cd/${this.inp.itemCd}`).then((res) => {
                if (res.data.resultCode == 1) {
                    return true;
                }
                return false;
            });
        },
        pushLayout(key, val){
            let index = -1;
            //QR用データ取得
            for(let data in this.layout){
                for(let items in this.layout[data]['data']){
                    index = this.layout[data]['data'][items].findIndex((p) => p.key === key);
                    if (this.layout[data]['data'][items].findIndex((p) => p.key === key) !== -1){
                        this.$set(this.layout[data]['data'][items][index], 'val', val);
                    }
                }
            }
        },
        listLayout(){
            let list = {};
            for(let data in this.layout){
                for(let items in this.layout[data]['data']){
                    this.layout[data]['data'][items].filter(function(item) {
                        list[item.key] = item.val;
                    });
                }
            }
            return list;
        },
        init(){
            //登録データ初期化
            for(let key in this.inp){
                this.inp[key] = '';
                if(key == 'dispFlg') this.inp[key] = 1;
            }

            //プレビューデータ初期化(Mサイズの値を設定)
            let layoutInit = {'width' : 112, 'height' : 157, 'margin1' : 1, 'margin2' : 5, 'margin3' : 10, 'item_type' : 1, 'font_family' : 'migu',
                            'font_lg' : 36, 'text_position1' : 109, 'sample1' : 'DUMMY',
                            'font_sm' : 24, 'text_position2' : 118, 'text_position3' : 127, 'text_position4' : 136, 'sample2' : 'DUMMY',
                            'font_cap' : 8, 'cap_position1' : 150, 'cap_position2' : 154, 'cap_margin1' : 10, 'cap_margin2' : 10,}

            for(let key in layoutInit){
                this.pushLayout(key, layoutInit[key]);
            }
        },
        clearError(){
            for (let key in this.err) {
                if (key === "kingaku") {
                    this.err[key].forEach((item, index) => {
                        for(let key2 in item){
                            this.err[key][index][key2] = [];
                        }
                    }, this);
                } else {
                    this.err[key] = [];
                }
            }
        },
        async clickBack(){
            this.$router.go(-1)
        },
        /**
         * 金額の削除 
         * @param {} index 
         */
        async delKingaku(index) {
            if (!this.kingakuList[index])
                return;

            const delitem = this.kingakuList[index].from_qty+"〜"+this.kingakuList[index].to_qty + "セット";
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003,delitem))
            if( !res ){
                return;
            }
            this.kingakuList.splice(index, 1);
            this.err.kingaku.splice(index, 1);
            this.kingakuListOld = JSON.parse(JSON.stringify(this.kingakuList));
            
        },
        /**
         * 金額の追加
         */
        async addKingaku() {
            let from_qty = 1
            if (this.kingakuList.length > 0) {
                const lastToQty = this.kingakuList[this.kingakuList.length - 1].to_qty;
                from_qty = lastToQty ? parseInt(lastToQty) + 1 : 1;
            }
            this.kingakuList.push({ from_qty: from_qty, to_qty: '', kingaku: '', zeigaku: '', hanbai_kingaku: '', hanbai_zeigaku: '', });
            this.err.kingaku.push({from_qty:[], to_qty:[], kingaku:[], zeigaku: [], hanbai_kingaku:[], hanbai_zeigaku: []});
            this.kingakuListOld = JSON.parse(JSON.stringify(this.kingakuList));
        },
    }
}
</script>