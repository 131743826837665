<template>
    <v-dialog v-model="dialog" persistent max-width="700px">
        <v-toolbar color="teal" height="40" dark>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card tile class="py-1 px-5">
        <div class="mb-2">
            <div class="print-table-title">注文内容</div>
            <div class="ml-7 mb-2 font-weight-bold text-subtitle2">
                <span class="mr-2">注文番号：</span><span>{{ chumonNo }}</span>
            </div>
            <div class="mx-5">
                <v-simple-table
                    dense
                    class="px-2 sms-list-table line tbl-max-height"
                    fixed-header
                >
                    <col style="width: 80px" />
                    <col style="width: auto" />
                    <col style="width: 100px" />
                    <col style="width: 150px" />
                    <thead>
                        <tr>
                            <th>CD</th>
                            <th>種類名称</th>
                            <th>サイズ</th>
                            <th>用紙タイプ</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dataList" :key="index">
                            <td>{{ contCodeFormat(item.cont_cd) }}</td>
                            <td>{{ item.type_name }}</td>
                            <td>{{ item.size_name }}</td>
                            <td>{{ item.item_name }}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>
        <div class="mb-3">
            <div class="print-table-title">送付先</div>
            <div class="shokai-content">
                <v-row class="ml-7 text-subtitle2">
                    <v-col cols="12">〒{{ sofusakiData.saki_post }} {{ sofusakiData.saki_address }}</v-col>
                    <v-col cols="2">会社名</v-col>    <v-col cols="9">：{{ sofusakiData.saki_corp_name }}</v-col>
                    <v-col cols="2">担当部署</v-col>  <v-col cols="9">：{{ sofusakiData.saki_tantou_busho }}</v-col>
                    <v-col cols="2">担当者名</v-col>  <v-col cols="9">：{{ sofusakiData.saki_tantou_name }}</v-col>
                    <v-col cols="2">電話</v-col>      <v-col cols="9">：{{ sofusakiData.saki_tel }}</v-col>
                    <v-col cols="2">メール</v-col>    <v-col cols="9">：{{ sofusakiMail }}</v-col>
                </v-row>
            </div>
        </div>
        <div class="mb-8">
            <div class="print-table-title">料金概算</div>
            <div class="shokai-content text-subtitle2">
                <v-row v-for="(kingaku, index) in kingakuData.perData" :key="index" class="ml-7">
                        <v-col cols="4">{{ kingaku.item_name }}</v-col>
                        <v-col cols="2">数量 {{ kingaku.per_item_cnt }}</v-col>
                        <v-col cols="6">{{ numberFormat(kingaku.per_total) }} 円</v-col>
                </v-row>
                <v-row class="ml-7">
                    <v-col cols="8" class="bor-btm mb-2"></v-col><v-col cols="4"></v-col>
                    <v-col cols="6">合計</v-col> <v-col cols="0">{{ numberFormat(kingakuData.total) }} 円</v-col>
                </v-row>
            </div>
        </div>
        <div class="about-ryokin mb-5">
            <div class="about-ryokin-title">料金概算について</div>
            <div class="about-ryokin-content">最終締切日時点での注文数量と送付先の送り先数により金額が決まるためここでの金額は概算金額（税込、送料は省く）となります。<br>
                実際の請求金額とは異なりますので金額は目安の金額となります。</div>
        </div>

        <div class="mx-5 text-right" elevation="0">
            <div>
                <v-btn class="btn-close ma-2" @click="clickClose()">閉じる</v-btn>
            </div>
        </div>
        </v-card>
    </v-dialog>
</template>
<style scoped>
.cont-cd {
    width: 100px;
}
.sofusaki{
    height: 40px;
}
.print-table-title{
    display: inline-block;
    border-bottom: 1px solid #000;
    width: 70%;
    margin: 15px 20px !important;
    font-weight: bold;
}
.shokai-content .col{
    padding: 2px 0 !important;
}
.shokai-content .bor-btm{
    border-bottom: 1px solid #000;
}
.about-ryokin{
    border: 1px dotted #000;
    margin: 0 30px;
    padding: 10px;
    font-size: 15px;
}
.about-ryokin-title{
    font-weight: bold;
    margin-bottom: 5px;
}
.tbl-max-height >>> .v-data-table__wrapper{
    max-height: 130px;
}
</style>
<script>
import {MESSAGE} from '../../messages'

export default {
    computed: {
    },
    data: () => ({
        title: '印刷コンテナ確認',
        dialog: false,
        chumonNo: '',
        dataList: [], //データリスト
        quantity: 1, //各注文個数
        orderStatus: 0, //発注状況ステータス
        statusList: [], //発注状況リスト
        sofusakiData: [], //送付先リスト
        kingakuData: [], //料金概算リスト
        sofusakiMail: '', //メールアドレスreplace用

    }),
    props: {
        //完了後のコールバック関数
        confirmDlgCallBack:{
            type:Function,
            require:true,
        }
    },
    mounted() {
    },
    methods: {
        //ダイアログ初期処理
        async open(no) {
            this.chumonNo = no;
            
            this.title = '注文照会';
            await this.getChumonInf();
                    
            this.dialog = true;
            this.sofusakiMail = this.sofusakiData.saki_mail.replace(/\|/g, ",");
        },
        /**
         * 注文情報の取得
         */
        async getChumonInf() {
            await this.axios.get(`api/admin-chumon-detail/${this.chumonNo}`).then((res) => {
                //用紙データ取得
                this.dataList = res.data.resultData.chumonData;

                //送付先データ取得
                this.sofusakiData = res.data.resultData.sofusakiData;

                //料金概算データ取得
                this.kingakuData = res.data.resultData.kingakuData;
            });
        },
        /**
         * 閉じるボタンクリック
         */
        clickClose() {
            this.init();
            this.dialog = false;
        },
        /**
         * 確認ダイアログの初期化
         */
        init(){
            this.stepPage = 1;
            this.selectSofusakiId = 0;
            this.addressB = [];
            this.dataList = [];
        },
    },
};
</script>