<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >ユーザー別当月設置件数集計一覧</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mx-auto">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold"
                    >抽出条件</v-card-subtitle
                >
                <v-spacer />
                <v-btn @click="clickExpand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>
            <v-expand-transition>
                <v-container fluid v-show="expand">
                    <v-row class="mt-2 ml-2 mt-0" dense>
                        <v-col>
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >会員CD
                                </v-subheader>
                                <v-text-field
                                    class="ma-2 member-cd"
                                    maxlength="10"
                                    type="tel"
                                    v-model="memberCd"
                                    ref="focusFlg"
                                    hide-details=""
                                    dense
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 ml-2" dense>
                        <v-col  class="mt-0">
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >会社名
                                </v-subheader>
                                <v-text-field
                                    class="ma-2 member-name"
                                    maxlength="40"
                                    v-model="corpName"
                                    dense
                                    hide-details=""
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 ml-2" dense>
                        <v-col  class="mt-0">
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >対象月
                                </v-subheader>
                                <input-date-fld
                                    class=""
                                    :isClearBtn="false"
                                    :date.sync="yearMonth"
                                    dateType="month"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 ml-2" dense>
                        <v-col  class="mt-0">
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >状態
                                </v-subheader>
                                <v-checkbox
                                    dense
                                    hide-details=""
                                    class="mr-5 d-inline-flex"
                                    v-model="searchStatus"
                                    v-for="list in jyotai"
                                    :error="isError(errorStatus)"
                                    :key="list.val"
                                    :label="list.kbn_name"
                                    :value="list.val"
                                />
                            </div>
                            <div
                                class="controll-error pl-15"
                                v-if="isError(errorStatus)"
                            >
                                <span
                                    v-html="dispError(errorStatus)"
                                ></span>
                            </div> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-divider class="mb-3" />
                                <v-btn large class="btn-close mr-4" @click="clickClear()"
                                    >クリア</v-btn
                                >
                                <v-btn large color="blue" @click="clickSearch()"
                                    >検&nbsp;索</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </v-card>

        <v-subheader class="text-h6 font-weight-black mt-3 mb-0"
            >会員一覧
            <v-spacer />
            <v-btn 
                color="deep-orange lighten-3" 
                @click="excelDl"    
                >
                <v-icon dark color="white" class="mr-2">
                    mdi-download
                </v-icon>
                エクスポート</v-btn
            >
        </v-subheader>
        <v-divider class="mb-1"></v-divider>
        <v-data-table :items='dataList' :headers='headers'
            class="datatable"
            dense
            height="418px"
            disable-pagination
            fixed-header
            fixed-tabs
            hide-default-footer>
        </v-data-table>
        <v-simple-table class="mt-5">
            <colgroup>
            <col style="width:auto" />
            <col style="width:100px" />
            <col style="width:100px" />
            <col style="width:100px" />
            <col style="width:119px" />
            </colgroup>
            <tr>
                <td class="text-right pr-2">総合計</td>
                <td class="text-right pr-2">{{gokei.toroku_gokei}}</td>
                <td class="text-right pr-2">{{gokei.haki_gokei}}</td>
                <td class="text-right pr-2">{{gokei.secchi_gokei}}</td>
                <td class="text-right pr-2">{{gokei.kaisyu_gokei}}</td>
            </tr>
        </v-simple-table>
        <v-divider class="mb-1"></v-divider>
            
    </v-container>
</template>
<style scope>
.title {
    width: 110px;
}
.input-control {
    width: 100%;
}

.gokei {
    position: absolute;
    left:auto;
    right: 480px;
}

.secchi-kei {
    position: absolute;
    right: 390px;
}
.toroku-kei {
    position: absolute;
    right: 260px;
}
.haki-kei {
    position: absolute;
    right: 170px;
}
.kaisyu-kei {
    position: absolute;
    right: 70px;
}
</style>
<script>
import { MESSAGE } from "../messages";
import { CONSTANT } from "../constant";
import InputDateFld from "../components/InputDateFld.vue";
const search = 0;
export default {
    name: "UserBetsu",
    components: { InputDateFld },
    data() {
        return {
            //-- ページ関連
            page: 1,
            perPage: 10,
            allPageCnt: 0,

            //-- expand
            isExpand: [],
            expand: true,

            memberList: [],
            memberCd: "",
            corpName: "",
            gokei:[],
            status: [],
            yearMonth: "",
            errorStatus: [],
            searchStatus:[2],
            jyotai: [
                { val: 2, kbn_name: this.getMemberStatusName(2)},
                { val: 3, kbn_name: this.getMemberStatusName(3)},
                { val: 4, kbn_name: this.getMemberStatusName(4)},
                { val: 9, kbn_name: this.getMemberStatusName(9)},
                { val: 8, kbn_name: this.getMemberStatusName(8)},
            ],
            headers:[
                { text: 'CD', value: 'member_cd', groupable: false},
                { text: '会社名', value: 'corp_name', groupable: false},
                { text: '登録数', value: 'all_count', groupable: false, width:"100px", align:"end"},
                { text: '廃棄数', value: 'dispos_count', groupable: false, width:"100px", align:"end"},
                { text: '設置数', value: 'rent_sys_count', groupable: false, width:"100px", align:"end"},
                { text: '回収数', value: 'end_sys_count', groupable: false, width:"100px", align:"end"},
            ],

            dataList:[],

        };
    },
    mounted() {
        // history.back の場合
        // if (history.state == "entry") {
        //     for (var key in this.$data) {
        //         if (sessionStorage.getItem(key) !== null) {
        //             this.$data[key] = JSON.parse(sessionStorage.getItem(key));
        //         }
        //     }
        // }
       

        history.replaceState("entry", null);    
        this.$refs.focusFlg.focus();

        // 当月を取得
        const d = new Date();
        const month = (d.getMonth()+1).toString().padStart(2, '0');
        this.yearMonth = `${d.getFullYear()}-`+ month;
        this.getSyukei();

        // 抽出条件セット
        const searchItem = JSON.parse(sessionStorage.getItem('userCont-search'));
        if(searchItem){
            this.memberCd = searchItem.memberCd;
            this.corpName = searchItem.corpName;
            this.yearMonth = searchItem.yearMonth;
            this.searchStatus = searchItem.status;
        }
    },
    methods: {
        /**
         * 状態保存
         * 現在の抽出条件等を SessionStorage へ保存
         */
        save_storage() {
            // for (var key in this.$data) {
            //     sessionStorage.setItem(key, JSON.stringify(this.$data[key]));
            // }
            let search = {
                memberCd:this.memberCd,
                corpName:this.corpName,
                yearMonth:this.yearMonth,
                status:this.searchStatus,
            }
            sessionStorage.setItem('userCont-search', JSON.stringify(search));
        },        
        async clickSearch(mode) {
                //検索ステータスが空の場合エラー表示
                if (await this.searchCheck()) {
                    return;
                }
            this.save_storage();
            await this.getSyukei();
        },
        async getMemberList() {
            return await this.axios
                .post("/api/member/search", {
                    memberCd: this.memberCd,
                    corpName: this.corpName,
                    status: this.status,
                    page: this.page,
                })
                .then((res) => {
                    this.memberList = res.data.memberInfo;
                    this.allPageCnt = res.data.cnt;
                });
        },
        async getSyukei() {
            return await this.axios
                .post("/api/get-syukei", {
                    memberCd: this.memberCd,
                    corpName: this.corpName,
                    yearMonth: this.yearMonth,
                    searchStatus:this.searchStatus
                })
                .then((res)=>{
                    this.memberList = res.data.syukei;
                    this.gokei = res.data.total;
                    this.dataList = res.data.list;

                })
        },
        /**
         * 検索条件を開く、閉じる
         */
        clickExpand() {
            this.expand = !this.expand;
            if (this.expand == false) {
                this.perPage = 15;
            } else {
                this.perPage = 10;
            }
            this.page = 1;
            this.getMemberList();
        },
        async searchCheck() {
            var errFlg = false;
            this.errorStatus = [];
            if (this.searchStatus == '') {
                this.errorStatus = [MESSAGE.formatByArr(MESSAGE.E002, `状態`)];
                errFlg = true;
            }
            return errFlg;
        },
        /**
        * エクセルダウンロード
        */
        async excelDl(){
//
            await this.axios.post('/api/get-syukei-excel',{
                    memberCd: this.memberCd,
                    corpName: this.corpName,
                    yearMonth: this.yearMonth,
                    searchStatus:this.searchStatus
                }).then(( res ) => {
                    if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
                    var blob = this.toBlob(res.data.resultData.excelData, 'application/vnd.ms-excel')  ;
                    var filename = res.data.resultData.fileName; 
                    var link = document.createElement('a');

                    document.body.appendChild(link);
                    link.href = URL.createObjectURL(blob);
                    link.download = filename; 
                    link.click();
                    document.body.removeChild(link);
                }

                });
        }, 
        toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	}, 
        /**
         * 検索クリアボタン
         */
        async clickClear() {
            for(let key in this.search){
                this.search[key] = '';
            }
            this.memberCd = "";
            this.corpName = "";
            this.searchStatus = [2];
            const d = new Date();
            const month = (d.getMonth()+1).toString().padStart(2, '0');
            this.yearMonth = `${d.getFullYear()}-`+ month;

            this.getSyukei();
        },
    },
};
</script>

