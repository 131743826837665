import moment from "moment"

export default {
    methods: {
        getVal(val){
            if(!val){
                return '';
            }else{
                return val;
            }
        },
        /*****************************************
         * ステータス名称変換 
         * @param {*} cd 
         * @returns 
         ****************************************/
         getMemberStatusName(cd) {
            var statusName = '';
            switch (cd) {
                case 1:
                    statusName = '新規申込';
                    break;
                case 2:
                    statusName = '稼働中';
                    break;
                case 3:
                    statusName = '停止中';
                    break;
                case 4:
                    statusName = '保留';
                    break;
                case 8:
                    statusName = '試用中';
                    break;
                case 9:
                    statusName = '退会';
                    break;
                default:
                    break;
            }
            return statusName;
        },
        /*****************************************
         * ステータス名称変換 
         * @param {*} cd 
         * @returns 
         ****************************************/
        getStatusName(cd) {
            var statusName = '';
            switch (cd) {
                case 0:
                    statusName = '完了';
                    break;
                case 1:
                    statusName = '設置中';
                    break;
                case 2:
                    statusName = '回収依頼中';
                    break;
                default:
                    break;
            }
            return statusName;
        },
        /*****************************************
         * 破棄区分名称変換 
         * @param {*} cd 
         ****************************************/
        getDisposName(cd) {
            if (cd == 1) {
                return "破棄";
            }
            return "";
        },
        /*****************************************
         * 配列が存在するかを確認する
         * @param {エラー配列} val 
         * @returns 
         ****************************************/
        isError: function (val) {
            if (val == undefined)
                return false;
            if (!Array.isArray(val))
                return false;
            if (val.length > 0) {
                return true;
            }
            return false;
        },
        /*****************************************
         * エラーメッセージ表示編集
         * コントロール下に表示するエラーメッセージのフォーマット
         ****************************************/
        dispError: function (val) {
            var err = '';
            for (var key in val) {
                err = err + `<p class="ma-0">${val[key]}</p>`
            }
            return err;
        },
        /*****************************************
         * 郵便番号チェック 
         * @param {郵便番号} str 
         * @returns 
         ****************************************/
        isPostCode: function (str) {
            if ( str.match(/^\d{3}-?\d{4}$/) ) {
                return true;
            } 
            return false;
        },
        /*****************************************
         * メールアドレスチェック 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
        isMailaddress: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 電話番号チェック 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
        isTelNo: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 半角英数チェック 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
        isHanEisu: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[A-Za-z0-9]*$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 半角英数チェック(記号有り) 
         * @param {文字列}} ymd 
         * @returns 
         ****************************************/
         isHanEisuKigo: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[A-Za-z0-9!#&]*$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 全角カタカナチェック
         * @param {文字列}} 
         * @returns 
         ****************************************/
         isZenKana: function (str) {
            str = (str == null) ? "" : str;
            if (str.match(/^[ァ-ロワヲンヴヵヶー]+$/)) {
                return true;
            } else {
                return false;
            }
        },
        /*****************************************
         * 日付のフォーマット 
         * @param {日付} ymd 
         * @returns 
         ****************************************/
        dateFormat: function (ymd, fmt='YYYY-MM-DD') {
            if (ymd == null || !moment(ymd).isValid) {
                return '';
            }

            const date = moment(ymd);
            return date.format(fmt);
        },
        dateFromToCheck: function (from, to) {

            if (to === null || !moment(to).isValid) {
                return true;
            }
            if (from === null || !moment(from).isValid) {
                return true;
            }
            const toDate = moment(to);
            const fromDate = moment(from);
            if (fromDate > toDate) {
                return false;
            }
            return true;
        },
        /**
         * 数値カンマ編集
         */
        numberFormat: function (price) {
            return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        },
        /**
         * 先頭の０削除
         */
         toZero: function (num) {
            if(num==null || num==''){
                return 0;
            }else{
                return num;
            }
        },
        /**
         * コンテナコードの編集
         */
        contCodeFormat: function (contId) {

            // var ptn = /^[0-9]+$/;
            // if (ptn.test(contId)) {
            //     return ('0000000' + contId).slice(-7);
            // } else {
            //     return contId;
            // }
            return contId;
        },
        /**
         * 担当者コードの編集
         */
        TantouCodeFormat: function (tantouCd) {

            var ptn = /^[0-9]+$/;
            if (ptn.test(tantouCd)) {
                return ('00000' + tantouCd).slice(-5);
            } else {
                return tantouCd;
            }
        },
        /**
         * 注文ステータス
         */
        chumonStatus: function (sts) {
            let statusName = '';
            let color = '';

            switch (sts) {
                case 0:
                    statusName = '未発注';
                    color = '#423b3b';
                    break;
                case 1:
                    statusName = '発注済';
                    color = '#0000cf';
                    break;
                case 2:
                    statusName = '発注済';
                    color = '#0000cf';
                    break;
                case 3:
                    statusName = '発送済';
                    color = '#e30000';
                    break;
                default:
                    statusName = '未発注';
                    color = '#423b3b';
                    break;
            }
            let vhtml = `<span style='color:${color}; font-weight: 1000;'>${statusName}</span>`
            return vhtml;
        },
        /**
         * 発注ステータス
         */
        hatchuStatus: function (sts) {
            let statusName = '';
            let color = '';

            switch (sts) {
                case 1:
                    statusName = '印刷準備中';
                    color = '#0000cf';
                    break;
                case 2:
                    statusName = '印刷中';
                    color = '#e30000';
                    break;
                case 3:
                    statusName = '発送済';
                    color = '#423b3b';
                    break;
                default:
                    statusName = '印刷準備中';
                    color = '#0000cf';
                    break;
            }
            let vhtml = `<span style='color:${color}; font-weight: 1000;'>${statusName}</span>`
            return vhtml;
        },
        /**
         * Confirm メッセージ表示
         * 「はい」「いいえ」ボタン
         * @param {タイトル} title 
         * @param {メッセージ内容} message 
         * @returns true:はい
         *          false:いいえ 
         */
        swalConfirm: function (title, message) {
            return this.$swal({
                title: title,
                text: message,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "はい",
                cancelButtonText: "いいえ"
            }).then((result) => {
                if (result.isConfirmed) {
                    /**はい */
                    return true;
                }
                /**いいえ */
                return false;
            })
        },
        /**
         * アラートメッセージ表示 
         * @param {*} title 
         * @param {*} message 
         * @param {*} icon 
         * @returns 
         */
        swalAlert: function (title, message, icon = "error") {
            return this.$swal({
                title: title,
                text: message,
                icon: icon
            })
        },
        /**
         * トーストメッセージ 
         * @param {*} title 
         * @param {*} icon 
         */
        swalToast: function (title, icon = "success") {
            this.$swal({
                text: title,
                toast: true,
                icon: icon,
                showConfirmButton: false,
                position: 'bottom-end', timer: 3000
            })
        },
        /**
         * 
         * @param {メッセージの内容} mes 
         *     "これは{0}です{1}"
         * @param {埋め込む引数} param 
         *     ["1111","2222"]
         * @returns 
         */
        generateMessage: function (mes, param) {
            let result
            for (i = 0; i < param.length; i++) {
                let index = "{" + i + "}"
                result = mes.replace(index, param[i])
            }
            return result
        },
        /*****************************************
         * 緯度・経度チェック
         * @param {緯度/経度} latlng 
         * @returns 
         ****************************************/
         latlngFormat: function (latlng) {
            latlng = typeof latlng == 'string'? latlng:String(latlng);

            if (latlng.match(/^[-]?[0-9\.]*$/) && latlng > -1000 && latlng < 1000) {
                return false;
            } else {
                return true;
            }
        },
    }
};