<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >会員管理</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mx-auto">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold"
                    >抽出条件</v-card-subtitle
                >
                <v-spacer />
                <v-btn @click="clickExpand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>
            <v-expand-transition>
                <v-container fluid v-show="expand">
                    <v-row class="mt-2 ml-2 mt-0" dense>
                        <v-col>
                            <div class="d-inline-flex">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >会員CD
                                </v-subheader>
                                <v-text-field
                                    class="ma-2 member-cd"
                                    maxlength="10"
                                    type="tel"
                                    v-model="searchMemberCd"
                                    ref="focusFlg"
                                    hide-details=""
                                    dense
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 ml-2" dense>
                        <v-col  class="mt-0">
                            <div class="d-inline-flex">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >会社名
                                </v-subheader>
                                <v-text-field
                                    class="ma-2 member-name"
                                    maxlength="40"
                                    v-model="searchCorpName"
                                    dense
                                    hide-details=""
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 ml-2" dense>
                        <v-col cols="12" class="d-flex">
                            <div class="title">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold"
                                    >状態</v-subheader
                                >
                            </div>
                            <div class="input-control">
                                <v-checkbox
                                    dense
                                    hide-details=""
                                    class="mr-5 d-inline-flex"
                                    v-model="searchStatus"
                                    v-for="list in jyotai"
                                    :error="isError(errorStatus)"
                                    :key="list.val"
                                    :label="list.kbn_name"
                                    :value="list.val"
                                />
                                <div
                                    class="controll-error"
                                    v-if="isError(errorStatus)"
                                >
                                    <span
                                        v-html="dispError(errorStatus)"
                                    ></span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-divider class="mb-3" />
                                <v-btn large class="btn-close mr-4" @click="clickClear()"
                                    >クリア</v-btn
                                >
                                <v-btn large color="blue" @click="clickSearch(0)"
                                    >検&nbsp;索</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </v-card>

        <v-subheader class="text-h6 font-weight-black mt-3 mb-0"
            >会員一覧
            <v-spacer />
            <v-btn color="deep-orange lighten-3" @click="clickNew"
                >会員新規作成</v-btn
            >
        </v-subheader>
        <v-divider class="mb-1"></v-divider>
        <v-simple-table fixed-header height="auto" class="datatable" dense>
            <template v-slot:default>
                <col style="width: 80px" />
                <col style="width: auto" />
                <col style="width: 130px" />
                <col style="width: 100px" />
                <col style="width: 200px" />
                <thead>
                    <tr>
                        <th>CD</th>
                        <th>会社名</th>
                        <th>電話番号</th>
                        <th>状態</th>
                        <th>編集</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in memberList" :key="item.member_no">
                        <td class="text-center">{{ item.member_cd }}</td>
                        <td class="text-left">{{ item.corp_name }}</td>
                        <td class="text-center">{{ item.tel }}</td>
                        <td class="text-center">{{ getMemberStatusName(item.status_flg) }}</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="teal lighten-3"
                                :disabled="item.status_flg!=1"
                                @click="clickApproval(item)"
                                >承認</v-btn
                            >
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="light-blue lighten-4"
                                :disabled="item.status_flg==1"
                                @click="clickEdit(item)"
                                >編集</v-btn
                            >
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="red lighten-4"
                                @click="clickDelete(item.member_id, item.member_cd)"
                                >削除</v-btn
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div style="border: solid 2px #ddd; text-align:center"
            class="grey--text text-lighten-5"
            v-if="memberList.length==0">該当するデータがありません。</div>
        <v-divider class="mb-1"></v-divider>
        <div v-if="allPageCnt!=1" class="text-center">
            <v-pagination
                v-model="page"
                circle
                :length="allPageCnt"
                :total-visible="20"
                @input="clickSearch(1)"
            ></v-pagination>
        </div>
    </v-container>
</template>
<style scope>
.title {
    width: 110px;
}
.input-control {
    width: 100%;
}
</style>
<script>
import { MESSAGE } from "../messages";
const search = 0;
export default {
    name: "Member",
    components: {},
    data() {
        return {
            //-- ページ関連
            page: 1,
            perPage: 10,
            allPageCnt: 0,

            //-- expand
            isExpand: [],
            expand: true,

            memberList: [],
            memberCd: "",
            corpName: "",
            status: [],
            errorStatus: [],
            jyotai: ([] = [
                { val: 1, kbn_name: this.getMemberStatusName(1)},
                { val: 2, kbn_name: this.getMemberStatusName(2)},
                { val: 3, kbn_name: this.getMemberStatusName(3)},
                { val: 4, kbn_name: this.getMemberStatusName(4)},
                { val: 9, kbn_name: this.getMemberStatusName(9)},
                { val: 8, kbn_name: this.getMemberStatusName(8)},
            ]),
            searchMemberCd: "",
            searchCorpName: "",
            searchStatus: [1],
        };
    },
    mounted() {
        // history.back の場合
        // if (history.state == "entry") {
        //     for (var key in this.$data) {
        //         if (sessionStorage.getItem(key) !== null) {
        //             this.$data[key] = JSON.parse(sessionStorage.getItem(key));
        //         }
        //     }
        // }
        this.page = this.$store.state.memberPage
        this.corpName = this.$store.state.memberCorpName
        this.memberCd = this.$store.state.memberCd
        this.status = this.$store.state.memberStatus
        if(this.page===undefined){
            this.page = 1;
        }
        // 抽出条件セット
        const searchItem =JSON.parse(sessionStorage.getItem('memberList-search'));
        if(searchItem){
            this.searchMemberCd = searchItem.memberCd;
            this.searchCorpName = searchItem.corpName;
            this.searchStatus = searchItem.status
        }

        // 全件取得
        // this.getMemberList();
        // 条件での取得
        this.clickSearch(0);
        history.replaceState("entry", null);
        this.$refs.focusFlg.focus();
    },
    methods: {
        /**
         * 状態保存
         * 現在の抽出条件等を SessionStorage へ保存
         */
        save_storage() {
            let search = {
                memberCd:this.searchMemberCd,
                corpName:this.searchCorpName,
                status:this.searchStatus,
            }
            // for (var key in this.$data) {
            sessionStorage.setItem('memberList-search', JSON.stringify(search));
        },      
        async clickSearch(mode) {

            if(mode == search){
                //検索ステータスが空の場合エラー表示
                if (await this.searchCheck()) {
                    return;
                }

                this.page = 1;
                this.memberCd = this.searchMemberCd;
                this.corpName = this.searchCorpName;
                this.status = this.searchStatus;
            }
            this.save_storage()
            this.getMemberList();

        },
        async getMemberList() {
            return await this.axios
                .post("/api/member/search", {
                    memberCd: this.memberCd,
                    corpName: this.corpName,
                    status: this.status,
                    page: this.page,
                    perPage:this.perPage,
                })
                .then((res) => {
                    this.memberList = res.data.memberInfo;
                    this.allPageCnt = res.data.cnt;
                });
        },
        /**
         * 検索条件を開く、閉じる
         */
        clickExpand() {
            this.expand = !this.expand;
            if (this.expand == false) {
                this.perPage = 15;
            } else {
                this.perPage = 10;
            }
            this.page = 1;
            this.getMemberList();
        },
        async searchCheck() {
            var errFlg = false;
            this.errorStatus = [];
            if (this.searchStatus == '') {
                this.errorStatus = [MESSAGE.formatByArr(MESSAGE.E002, `状態`)];
                errFlg = true;
            }
            return errFlg;
        },
        inputPageAndSearchs(){
            this.$store.commit('setMemberPage', this.page);
            this.$store.commit('setMemberCorpName',this.corpName);
            this.$store.commit('setMemberCd',this.memberCd);
            this.$store.commit('setMemberStatus',this.status);
        },
        /**
         * 新規作成ボタン
         */
        clickNew() {
            this.inputPageAndSearchs();
            this.$router.push({name:"MemberEdit", params:{memberId:''}});
        },
        /**
         * 承認ボタン
         */
        clickApproval(memberInf){
            this.inputPageAndSearchs();
            this.$router.push({name:"Approval", params:{memberId:memberInf.member_id}});
        },
        /**
         * 編集ボタン
         */
        clickEdit(memberInf){
            this.inputPageAndSearchs();
            this.$router.push({name:"MemberEdit", params:{memberId:memberInf.member_id}});
        },
        async clickDelete(memberInf, cd){
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, cd));

            // 「いいえ」の場合、処理しない
            if (!res) {
                return;
            }

            await this.axios.delete(`/api/member/${memberInf}`)
                .then((res) => {
                    this.swalToast(MESSAGE.formatByArr(MESSAGE.I003, cd));               
                });
            
            this.getMemberList();
        },
        /**
         * 検索クリアボタン
         */
        async clickClear() {
            for(let key in this.search){
                this.search[key] = '';
            }
            this.searchMemberCd = "";
            this.searchCorpName = "";
            this.searchStatus = [1];
            this.clickSearch(0);
        },
    }
};
</script>

