<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >お問合せ管理</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-subheader class="text-h6 font-weight-black mt-3 mb-0"
            >お問合せ一覧
            <v-spacer />
            <v-btn 
                color="deep-orange lighten-3" 
                @click="excelDl"    
                >
                <v-icon dark color="white" class="mr-2">
                    mdi-download
                </v-icon>
                エクスポート</v-btn
            >
        </v-subheader>
        <v-divider class="mb-1"></v-divider>
        <v-simple-table fixed-header height="auto" class="datatable" dense>
            <colgroup>
                <col style="width:6%;" />
                <col style="width:10%;" />
                <col style="width:20%;" />
                <col style="width:5%;" />
                <col style="width:25%;" />
                <col style="width:25%;" />
            </colgroup>
            <tr>
                <th @click="sortInq(flg=1, 'id', !inqSearch.isDesc)" class="text-left pl-3">
                    CD
                    <v-icon v-if="inqSearch.isDesc&inqSearch.sortKey=='id'" dense
                        >mdi-arrow-down</v-icon>
                    <v-icon v-if="!inqSearch.isDesc&inqSearch.sortKey=='id'" dense
                        >mdi-arrow-up</v-icon>
                </th>
                <th @click="sortInq(flg=1, 'inq_date', !inqSearch.isDesc)" class="text-left pl-3">
                    日付
                    <v-icon v-if="inqSearch.isDesc&inqSearch.sortKey=='inq_date'" dense
                        >mdi-arrow-down</v-icon>
                    <v-icon v-if="!inqSearch.isDesc&inqSearch.sortKey=='inq_date'" dense
                        >mdi-arrow-up</v-icon>
                </th>
                <th @click="sortInq(flg=1, 'member_id', !inqSearch.isDesc)" class="text-left pl-3">
                    会員
                    <v-icon v-if="inqSearch.isDesc&inqSearch.sortKey=='member_id'" dense
                        >mdi-arrow-down</v-icon>
                    <v-icon v-if="!inqSearch.isDesc&inqSearch.sortKey=='member_id'" dense
                        >mdi-arrow-up</v-icon>
                </th>
                <th @click="sortInq(flg=1, 'inq_cd', !inqSearch.isDesc)" class="text-left pl-3">
                    区分
                    <v-icon v-if="inqSearch.isDesc&inqSearch.sortKey=='inq_cd'" dense
                        >mdi-arrow-down</v-icon>
                    <v-icon v-if="!inqSearch.isDesc&inqSearch.sortKey=='inq_cd'" dense
                        >mdi-arrow-up</v-icon>
                </th>
                <th @click="sortInq(flg=1, 'model_name', !inqSearch.isDesc)" class="text-left pl-3">
                    機種名
                    <v-icon v-if="inqSearch.isDesc&inqSearch.sortKey=='model_name'" dense
                        >mdi-arrow-down</v-icon>
                    <v-icon v-if="!inqSearch.isDesc&inqSearch.sortKey=='model_name'" dense
                        >mdi-arrow-up</v-icon>
                </th>
                <th @click="sortInq(flg=1, 'inq_text', !inqSearch.isDesc)" class="text-left pl-3">
                    内容
                    <v-icon v-if="inqSearch.isDesc&inqSearch.sortKey=='inq_text'" dense
                        >mdi-arrow-down</v-icon>
                    <v-icon v-if="!inqSearch.isDesc&inqSearch.sortKey=='inq_text'" dense
                        >mdi-arrow-up</v-icon>
                </th>
                <th>
                    処理
                </th>
            </tr>
            <tr v-for="(item, index) in inqList" :key="index">
                        <td class="text-center">{{ item.id }}</td>
                        <td class="text-center" >{{  dateFormat(item.inq_date) }}</td>
                        <td class="text-left" >
                            <div class="omitted-member-info">
                                <div class="omitted-div">
                                    [{{  item.member_id }}]&nbsp;{{ item.corp_name }}
                                </div>
                            </div>
                        </td>
                        <td class="text-center">{{ kubunName(item.inq_cd) }}</td>
                        <td class="text-left">
                            <div class="omitted">
                                <div class="omitted-div">
                                    {{ item.model_name }}
                                    </div>
                                </div>
                            </td>
                        <td class="text-left">
                            <div class="omitted">
                                <div class="omitted-div">
                                    {{ item.inq_text }}
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="light-blue lighten-4"
                                :disabled="item.status_flg == 1"
                                @click="clickInfo(item.id)"
                                >詳細</v-btn
                            >
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="red lighten-4"
                                @click="clickDelete(item.id)"
                                >削除</v-btn
                            >
                        </td>
            </tr>
        </v-simple-table>
        <v-divider class="mb-1"></v-divider>
        <div class="text-center">
            <v-pagination
                v-model="inqSearch.page"
                circle
                :length="inqSearch.allPageCnt"
                :total-visible="20"
                @input="getInquiries()"
            ></v-pagination>
        </div>
        <dlg-inq-info ref="refInqDialog" />
    </v-container>
</template>
<style scope>
.omitted {
    width: 300px;
    max-width: 100%;
    white-space: nowrap;
}
.omitted-member-info {
    width: 280px;
    max-width: 100%;
    white-space: nowrap;
}
.omitted-div {
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
<script>
import { CONSTANT } from '../constant';
import { MESSAGE } from "../messages";
import DlgInqInfo from "../components/dialog/DlgInquiries.vue";
export default {
    components: {DlgInqInfo},
    data() {
        return {
            inqList: [],
            //-- ページとリストのソート関連
            inqSearch:{
                page: 1,
                perPage: 10,
                allPageCnt: 0,
                sortKey: "id",
                isDesc: false,
            }
        }
    },
    mounted() {
        this.getInquiries(); 
    },
    methods: {
        kubunName(kubun) {
            let name = '';
            switch (kubun)
            {
                case 1:
                    name = "要望";
                    break;
                case 2:
                    name = "不具合";
                    break;
                case 3:
                    name = "その他";
                    break;
            }
            return name;
        },
        async sortInq(flg, sortKey, isDesc) {
            this.inqSearch.sortKey=sortKey;
            this.inqSearch.isDesc=isDesc;
            await this.getInquiries(flg);
            return this.inqList ;
        },
        async getInquiries(flg = 0) {

            if(flg == 1){
                this.inqSearch.page=1;
            }
            const result = await this.axios.post('/api/inquirie/get-inquiries',this.inqSearch)
            this.inqList=[]
            if (result.data.resultCode != CONSTANT.RETURN_NORMAL) {
                return
            }
            this.inqList = result.data.resultData.inquiries;
            this.inqSearch.allPageCnt = result.data.resultData.cnt;
        },
        clickInfo(itemId){
            this.$refs.refInqDialog.detailsView(itemId);
        },
        async clickDelete(itemId){
            const ret = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, '問い合わせ No.' + itemId));
            if(!ret){
                return ;
            }

			const res = await this.axios.post('/api/inquirie/inq-delete',{inq_id:itemId});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                //削除処理完了済の処理
                this.swalToast(MESSAGE.formatByArr(MESSAGE.I003, '問い合わせ No.' + String(itemId)));
                this.getInquiries()
			}
        },
        /**
        * エクセルダウンロード
        */
        async excelDl(){

            await this.axios.post('/api/inquirie/inq-excel',{
                    sortKey: this.inqSearch.sortKey,
                    isDesc: this.inqSearch.isDesc
                }).then(( res ) => {
                    if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
                    var blob = this.toBlob(res.data.resultData.excelData, 'application/vnd.ms-excel')  ;
                    var filename = res.data.resultData.fileName; 
                    var link = document.createElement('a');

                    document.body.appendChild(link);
                    link.href = URL.createObjectURL(blob);
                    link.download = filename; 
                    link.click();
                    document.body.removeChild(link);
                }

                });
        },
        toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},
    }
}
</script>