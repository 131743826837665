<template>
    <v-container fluid class="mt-2">
        <v-row>
        <v-col>
        <v-card class="mx-auto">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold">
                    マスタ設定
                </v-card-subtitle>
            </v-system-bar>
            <v-row class="mt-5">
            <v-col cols="10">
            <v-tabs class="ma-5" v-model="activeTab">
                <v-tab outlined v-bind:class="['tab-title', { 'active-title': (activeTab==0)}]">
                    {{ kiyaku.name }}
                </v-tab>
                <v-tab outlined v-bind:class="['tab-title', { 'active-title': (activeTab==1)}]">
                    {{ kiyakuGenba.name }}
                </v-tab>
                <v-tab outlined v-bind:class="['tab-title', { 'active-title': (activeTab==1)}]">
                    {{ qrInfo.name }}
                </v-tab>
                <v-tab v-bind:class="['tab-title', { 'active-title': (activeTab==index+2)}]"
                    v-for="(mail, index) in mail_templates"
                    :key="mail.id"
                >
                    {{ mail.temp_name }}
                </v-tab>
                <v-tab-item>
                    <v-card outlined class="pa-10">
                        <v-simple-table class="tem-tbl mt-4 pt-4 pr-4 pl-4 pb-4">
                            <tr>
                                <th>利用規約</th>
                                <td>
                                    <v-textarea
                                        outlined
                                        class="ma-2 input-content"
                                        :error="isError(err.kiyaku)"
                                        v-model="kiyaku.kiyaku"
                                        ref="focusFlg"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.kiyaku)"
                                    >
                                        <span v-html="dispError(err.kiyaku)"></span>
                                    </div>

                                </td>
                            </tr>
                        </v-simple-table>
                        <v-btn class="btn-edit ma-4" @click="updateTemp(kiyaku, 1)" large>保存</v-btn>
                    </v-card>
                </v-tab-item>
                                <v-tab-item>
                    <v-card outlined class="pa-10">
                        <v-simple-table class="tem-tbl mt-4 pt-4 pr-4 pl-4 pb-4">
                            <tr>
                                <th>利用規約（現場）</th>
                                <td>
                                    <v-textarea
                                        outlined
                                        class="ma-2 input-content"
                                        :error="isError(err.kiyakuGenba)"
                                        v-model="kiyakuGenba.kiyaku"
                                        ref="focusFlg"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.kiyakuGenba)"
                                    >
                                        <span v-html="dispError(err.kiyakuGenba)"></span>
                                    </div>
                                    
                                </td>
                            </tr>
                        </v-simple-table>
                        <v-btn class="btn-edit ma-4" @click="updateTemp(kiyakuGenba, 2)" large>保存</v-btn>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card outlined class="pa-10">
                        <v-simple-table class="tem-tbl mt-4 pt-4 pr-4 pl-4 pb-4">
                            <tr>
                                <th>QRコード印刷注文内容</th>
                                <td>
                                    <v-textarea
                                        outlined
                                        class="ma-2 input-content"
                                        :error="isError(err.qrInfo)"
                                        v-model="qrInfo.info"
                                        ref="focusFlg"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.qrInfo)"
                                    >
                                        <span v-html="dispError(err.qrInfo)"></span>
                                    </div>
                                </td>
                            </tr>
                        </v-simple-table>
                        <v-btn class="btn-edit ma-4" @click="updateQrInfo(qrInfo)" large>保存</v-btn>
                    </v-card>
                </v-tab-item>
                <v-tab-item 
                    v-for="(mail, index) in mail_templates"
                    :key="index" 
                >
                    <v-card outlined class="pa-10">
                        <v-simple-table class="tem-tbl mt-4 pt-4 pr-4 pl-4 pb-4">
                            <tr>
                                <th>タイトル</th>
                                <td>
                                    <v-text-field
                                    solo
                                    maxlength="80"
                                    type="text"
                                    outlined
                                    hide-details="auto"
                                    dense
                                    class="ma-2 input-title"
                                    :error="isError(err.subject)"
                                    v-model="mail.subject"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.subject)"
                                    >
                                        <span v-html="dispError(err.subject)"></span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>送信元</th>
                                <td>
                                    <v-text-field
                                    solo
                                    maxlength="60"
                                    type="email"
                                    outlined
                                    hide-details="auto"
                                    dense
                                    class="ma-2 input-mail"
                                    :error="isError(err.mail_from)"
                                    v-model="mail.mail_from"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.mail_from)"
                                    >
                                        <span v-html="dispError(err.mail_from)"></span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>内容</th>
                                <td>
                                    <v-textarea
                                    outlined
                                    class="ma-2 input-content"
                                    :error="isError(err.content)"
                                    v-model="mail.content"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.content)"
                                    >
                                        <span v-html="dispError(err.content)"></span>
                                    </div>
                                </td>
                            </tr>
                        </v-simple-table>
                        <v-btn class="btn-edit ma-4" @click="updateTemp(mail, 3)" large>保存</v-btn>
                    </v-card>
                </v-tab-item>
            </v-tabs>
            </v-col>
            <v-col cols="2">
            </v-col>
            </v-row>
        </v-card>
        </v-col>
        </v-row>
    </v-container>
</template>
<style lang="scss" scope>
.tab-title {
    border: 0.5px solid;
    border-color: rgba(112, 112, 112, 0.651);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.v-tab.active-title {
    background-color: #efebeb;
}

.tem-tbl {
    th {
        color: white;
        background-color: #999;
        width: 150px;
    }
    
    .input-title {
        width: 80%;
    }
    
    .input-mail {
        width: 50%;
    }
    
    .input-content {
        width: 80%;
    }
}
</style>

<script>
import { MESSAGE } from '../messages';

export default {
    name: 'Master',
    data(){
        return {
            activeTab: "",
            kiyaku: { name: '利用規約', kiyaku: '' },
            kiyakuGenba: { name: '利用規約（現場）', kiyaku: '' },
            qrInfo: {name: 'QRコード印刷注文', info: ''},
            mail_templates: [],

            //エラー表示用
            err: ({} = {
                kiyaku: [],
                kiyakuGenba: [],
                qrInfo: [],
                subject: [],
                mail_from: [],
                content: []
            })
        }
    },
    mounted: function() {
        this.axios.get('api/master').then((res) => {
            this.kiyaku.kiyaku = res.data.kiyaku.kiyaku;
            this.kiyakuGenba.kiyaku = res.data.kiyaku_genba.kiyaku_genba;
            this.qrInfo.info = res.data.qrcode_info.qrcode_print_info;
            this.mail_templates = res.data.mail_templates;
            this.$refs.focusFlg.focus();
        });
    },
    methods: {
        /**
         * テンプレートの登録
         */
        updateTemp: async function (tempData, tempType) {

            this.clearErr();

            if(tempType==1){
                var tempId = 1;
                var postData = {
                    temp_type: tempType,
                    kiyaku: tempData.kiyaku
                }
                
                var result = await this.inputCheckKiyaku(tempData, tempType);
            }
            if(tempType==2){
                var tempId = 1;
                var postData = {
                    temp_type: tempType,
                    kiyaku_genba: tempData.kiyaku
                }

                var result = await this.inputCheckKiyaku(tempData, tempType);
            }
            if(tempType==3){
                var tempId = tempData.mail_id;
                var postData = {
                    temp_type: tempType,
                    subject: tempData.subject,
                    mail_from: tempData.mail_from,
                    content: tempData.content
                }

                var result = await this.inputCheckMail(tempData);
            }

            if (result) {
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }
            
            // 確認メッセージ
            var res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.R002
            );
            if (!res) {
                return;
            }

            // 更新処理
            this.axios
            .put(`api/master/${tempId}`, postData)
            .then((res) => {
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,`更新内容`)  );
            });
        },
        /**
         * QRコード印刷注文文の登録
         */
        updateQrInfo: async function(qrInfo){
            this.clearErr();

            if(qrInfo.info=== "" || qrInfo.info==null){
                this.err.qrInfo = [
                    MESSAGE.formatByArr(MESSAGE.E001, "QRコード印刷注文内容")
                ];

                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return true;
            }

            let postData = {
                qrcode_info: qrInfo.info
            }

            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.R002
            );
            if (!res) {
                return;
            }

            // 更新処理
            this.axios
            .put(`api/qr-info-update`, postData)
            .then((res) => {
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I001,`更新内容`)  );
            })
            
            return false;
        },
        /**
         * エラーチェック(規約)
         */
        inputCheckKiyaku: function (kiyaku, temp_type) {
            if (kiyaku.kiyaku=== "" || kiyaku.kiyaku==null) {

                if(temp_type == 1){
                    this.err.kiyaku = [
                        MESSAGE.formatByArr(MESSAGE.E001, "利用規約"),
                    ];
                }
                if(temp_type == 2){
                    this.err.kiyakuGenba = [
                        MESSAGE.formatByArr(MESSAGE.E001, "利用規約（現場）"),
                    ];
                }

                return true;
            }
            return false;
        },
        /**
         * エラーチェック(メール)
         */
        inputCheckMail: function (mail) {
            if (mail.subject === "" || mail.subject==null) {
                this.err.subject = [
                    MESSAGE.formatByArr(MESSAGE.E001, "タイトル"),
                ];
            }

            if (mail.mail_from === "" || mail.mail_from==null || !this.isMailaddress(mail.mail_from)) {
                this.err.mail_from = [
                    MESSAGE.formatByArr(MESSAGE.E005, "メールアドレスの形式"),
                ];
            }

            if (mail.content === "" || mail.content==null) {
                this.err.content = [
                    MESSAGE.formatByArr(MESSAGE.E001, "内容"),
                ];
            }
            
            for (var key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        clearErr: function () {
            for (var key in this.err) {
                this.err[key] = [];
            }
        }
    }

}
</script>
