<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >承認処理</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
    <v-stepper v-model="valStep">
        <v-stepper-header>
                <v-stepper-step  :complete="valStep > 1" step="1" >
                    申込内容
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step  v-if="inp.statusFlg==2" :complete="valStep > 2" step="2" >
                    メール送信 
                </v-stepper-step>
                <v-divider  v-if="inp.statusFlg==2"></v-divider>

                <v-stepper-step  :complete="compStep3" :step="compStep" >
                    完了 
                </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-card class="mb-1">
                    <v-simple-table
                        class="sms-list-table approval mt-4 pt-4 pr-4 pl-4 pb-4"
                    >
                        <tr>
                            <th>会員ID</th>
                            <td>
                                <div class="my-3 ml-3">

                                    <v-sheet
                                        outlined
                                        width="80"
                                        class="rounded pa-2 dspfld text-center"
                                        elevation="0"
                                    >
                                    {{ inp.memberId }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <th>状態</th>
                            <td>
                                <v-radio-group class="pl-2 pb-3 pt-0" v-model="inp.statusFlg" hide-details="" dense row>
                                    <v-radio dense
                                        v-for="list in jyotai"
                                        :key="list.val"
                                        :label="list.kbn_name"
                                        :value="list.val"
                                    />
                                </v-radio-group>
                            </td>
                        </tr>
                        <tr v-show="inp.statusFlg==2">
                            <th>会員CD</th>
                            <td>
                                <div class="my-0 ml-2 d-inline-flex">
                                    <v-text-field
                                        solo
                                        maxlength="8"
                                        type="tel"
                                        outlined
                                        :error="isError(err.memberCd)"
                                        hide-details="auto"
                                        dense
                                        class="ma-2 tokui-cd"
                                        v-model="inp.memberCd"
                                    />
                                    <div class="text-caption pt-6 ">会員コードは、既に登録されているコードと重複しないコードを入力します</div>
                                </div>
                                <div class="my-0 ml-2">
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.memberCd)"
                                    >
                                        <span
                                            v-html="dispError(err.memberCd)"
                                        ></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="inp.statusFlg==2">
                            <th>パスワード</th>
                            <td>
                                <div class="my-3 ml-2">
                                    <div class="d-inline-flex mb-3">
                                        <v-text-field
                                            solo
                                            maxlength="10"
                                            type="tel"
                                            outlined
                                            :error="isError(err.password)"
                                            hide-details="auto"
                                            dense
                                            class="ma-2 password"
                                            v-model="inp.password"
                                        />
                                        <v-btn class="ma-2 btn-edit" @click="clickGetPassword">自動生成</v-btn>

                                        <div class="text-caption pt-6 ">自動生成すると数字と英数を組み合わせてパスワードが自動生成されます。</div>
                                    </div>
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.password)"
                                    >
                                        <span
                                            v-html="dispError(err.password)"
                                        ></span>
                                    </div>
                                    <div class="d-inline-flex ml-2">
                                        <div class="mt-2 text-subtitle-2">
                                            パスワード強度: <span class="font-weight-bold">{{ meterVal }}</span>
                                            <v-progress-linear
                                            class="passMeter mt-2"
                                            background-color="grey lighten-3"
                                            :value="meter"
                                            :color="meterColor"
                                            height="8"
                                            >
                                            </v-progress-linear>
                                        </div>
                                        <ul class="passMeterCap mt-2 ml-2">
                                            <li>8文字以上の半角英数字で入力してください。</li>
                                            <li>英大文字/英小文字/数字/記号(!#&)の中から、3種類以上を含むパスワードを設定してください。</li>
                                            <li>パスワード強度が「弱い」または「やや弱い」の場合は、パスワードを登録できません。</li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>会社名</th>
                            <td>
                                <div class="my-3 ml-3">
                                    <v-sheet
                                        outlined
                                        width="500"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.corpName }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>会社名（カナ）</th>
                            <td>
                                <div class="my-3 ml-3">
                                    <v-sheet
                                        outlined
                                        width="500"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.corpKana }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>代表者名</th>
                            <td>
                                <div class="my-2 ml-3 d-inline-flex">
                                    <v-sheet
                                        outlined
                                        width="250"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.memberName }}
                                    </v-sheet>
                                <div class="my-2 ml-5 mr-2">(カナ)</div>
                                    <v-sheet
                                        outlined
                                        width="250"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.memberKana }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>担当者名</th>
                            <td>
                                <div class="my-2 ml-3 d-inline-flex">
                                    <v-sheet
                                        outlined
                                        width="250"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.tantouName }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>郵便番号</th>
                            <td>
                                <div class="my-2 ml-3">
                                    <v-sheet
                                        outlined
                                        width="100"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.post }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>緯度・経度</th>
                            <td>
                                <div class="ml-1 d-inline-flex">
                                    <div>
                                        <v-text-field class="ma-2" maxlength="12" type="tel" label="緯度" v-model="inp.lat" :error="isError(err.lat)" outlined hide-details="" dense />
                                        <div class="controll-error ml-2" v-show="isError(err.lat)">
                                            <span v-html="dispError(err.lat)"></span>
                                        </div>
                                    </div>
                                    <div>
                                        <v-text-field class="ma-2" maxlength="12" type="tel" label="経度" v-model="inp.lng" :error="isError(err.lng)" outlined hide-details="" dense />
                                        <div class="controll-error ml-2" v-show="isError(err.lng)">
                                            <span v-html="dispError(err.lng)"></span>
                                        </div>
                                    </div>
                                    <v-btn class="ma-2" @click="clickMap">地図表示</v-btn>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>住所</th>
                            <td>
                                <div class="my-3 ml-3">
                                    <v-sheet
                                        outlined
                                        width="700"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.address1 }}
                                    </v-sheet>
                                </div>
                                <div class="my-3 ml-3">
                                    <v-sheet
                                        outlined
                                        width="700"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.address2 }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>電話/FAX番号</th>
                            <td>
                                <div class="my-2 ml-3 d-inline-flex">
                                    <div class="my-2 mr-3">電話</div>
                                    <v-sheet
                                        outlined
                                        width="130"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.tel }}
                                    </v-sheet>
                                    <div class="my-2 ml-5 mr-3">FAX</div>
                                    <v-sheet
                                        outlined
                                        width="130"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                        {{ inp.fax }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>メールアドレス</th>
                            <td>
                                <div class="ma-3">
                                        <div v-for="(mail, index) in inp.mail" :key="index" class="mb-3">
                                            <div class="d-inline-flex align-center">
                                            <span>{{index + 1}}：</span>
                                            <v-sheet
                                                outlined
                                                width="500"
                                                class="rounded pa-2 dspfld"
                                                elevation="0"
                                            >
                                                {{ mail }}
                                            </v-sheet>
                                            </div>
                                        </div>
                                </div>
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card>

                <v-btn class="btn-close ma-3" @click="clickCancel"> 一覧にもどる 
                </v-btn>

                <v-btn class="btn-edit ma-3" width="140" @click="clickStep1"> 次へ 
                    <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>

            </v-stepper-content>

            <v-stepper-content step="2">
                <v-card
                    class="mb-12"
                >

                    <v-simple-table
                        class="sms-list-table approval mt-4 pt-4 pr-4 pl-4 pb-4"
                    >
                        <tr>
                            <th>送信元（FROM）</th>
                            <td>
                                <div class="my-3 ml-3">
                                    <v-sheet
                                        outlined
                                        width="500"
                                        class="rounded pa-2 dspfld"
                                        elevation="0"
                                    >
                                    {{ inp.mailFrom }}
                                    </v-sheet>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>送信先（TO）</th>
                            <td>
                                <div class="my-3 ml-1">
                                    <div v-for="(mail, index) in inp.mail" :key="index">
                                    <v-text-field
                                        :solo="!mailToFlg"
                                        outlined
                                        :disabled="mailToFlg"
                                        maxlength="60"
                                        type="tel"
                                        :error="isError(err.mail)"
                                        hide-details=""
                                        dense
                                        class="ma-2 mailTo"
                                        :class="{'dspfldMailTo' : mailToFlg}"
                                        elevation="0"
                                        v-model="inp.mail[index]"
                                    >
                                    </v-text-field>
                                    </div>
                                    </div>
                                </div>
                                <div
                                    class="controll-error ml-3"
                                    v-show="isError(err.mail)"
                                >
                                    <span v-html="dispError(err.mail)"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>タイトル</th>
                            <td>
                                <div class="my-3 ml-1">
                                    <v-text-field
                                        solo
                                        maxlength="40"
                                        type="tel"
                                        outlined
                                        :error="isError(err.mailSubject)"
                                        hide-details="auto"
                                        dense
                                        class="ma-2 mail-subject"
                                        v-model="inp.mailSubject"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.mailSubject)"
                                    >
                                        <span
                                            v-html="dispError(err.mailSubject)"
                                        ></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>本文</th>
                            <td>
                                <div class="my-3 ml-1">
                                    <v-textarea
                                        solo
                                        maxlength="1000"
                                        outlined
                                        :error="isError(err.mailContent)"
                                        hide-details="auto"
                                        dense
                                        class="ma-2"
                                        v-model="inp.mailContent"
                                    />
                                    <div
                                        class="controll-error ml-2"
                                        v-show="isError(err.mailContent)"
                                    >
                                        <span
                                            v-html="dispError(err.mailContent)"
                                        ></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card>
                <dlg-map ref="refDlgMap" :dlg-call-back="callBackMap"/>
                <v-btn class="btn-close ma-2" @click="clickBackStep1"> もどる </v-btn>
                <v-btn class="btn-edit ma-2" width="140"  @click="clickStep2"> 完了 
                    <v-icon>mdi-chevron-double-right</v-icon>
                </v-btn>

            </v-stepper-content>

            <v-stepper-content step="3">
                <v-card
                    outlined
                    class="ma-5 pa-5 text-center"
                    height="200px"
                >
                <v-sheet class="ma-auto" height="150" width="400" color="blue lighten-5" outlined>
                    <div class="ma-5 text-h6 font-weight-bold">完了しました</div>
                    <v-divider class="mx-3"  />
                    <v-btn class="mt-5 btn-close" @click="clickCancel">一覧へもどる</v-btn>
                </v-sheet>
                </v-card>

            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
    </v-container>
</template>
<style lang="scss">
.approval {
    th {
        width: 150px;
    }
    .dspfld {
        border: 1px solid #aaa;
        background-color: #eee;
        min-height: 40px;
    }
    .dspfldMailTo  {
        .v-input__slot{
            background-color:#eee !important;
            input{
                color:rgba(0, 0, 0, 0.87) !important;

            }

        }
    }
}
.passMeter{
    width:300px;
}
.passMeterCap{
    line-height:1.4;
    font-size:10px;
}
.mailTo{
    width:500px !important;
}
</style>
<script>
import { MESSAGE } from "../messages";
import DlgMap from "../components/dialog/DlgMap"
import InputDateFld from "../components/InputDateFld.vue";
export default {
    components: { DlgMap, InputDateFld },
    computed:{
        compStep(){
            if(this.inp.statusFlg==2){
                return '3';
            }
            return '2'
        },
        //パスワードの強度チェック
        meter(){
            let mt = 0;
            this.meterPoint = 0;
            this.meterColor = '';
            this.meterVal = '';

            mt += /.{8,}/.test(this.inp.password) ? 40 : 0
            mt += /\d/.test(this.inp.password)    ? 15 : 0
            mt += /[a-z]/.test(this.inp.password) ? 15 : 0
            mt += /[A-Z]/.test(this.inp.password) ? 15 : 0
            mt += /[#!&]/.test(this.inp.password) ? 15 : 0

            if(1 <= mt){
                this.meterPoint = 25
                this.meterColor = '#FF0000'
                this.meterVal = '弱い'
            }
            if(60 <= mt){
                this.meterPoint = 50
                this.meterColor = 'orange'
                this.meterVal = 'やや弱い'
            }
            if(80 <= mt){
                this.meterPoint = 75
                this.meterColor = 'lime'
                this.meterVal = 'やや強い'
            }
            if(100 == mt){
                this.meterPoint = 100
                this.meterColor = 'green'
                this.meterVal = '強い'
            }

            return this.meterPoint;
        },
        //送信先が使用不可のメールだったら再入力させる。
        mailToFlg(){
            if(this.err.mail == ''){
                return true;
            }else{
                return false;
            }
        },
    },
    data() {
        return {
            steps: [
                { title: "申込内容", step: 1 },
                { title: "メール送信", step: 2 },
                { title: "完了", step: 3 },
            ],

            valStep: 1,

            saveLoading: false,
            compStep1:true,
            compStep2:false,
            compStep3:false,

            mailCont:{},
            content:'',

            memberCdDef: '',

            //パスワードの強度チェック用
            meterPoint: '',
            meterColor: '',
            meterVal: '',

            jyotai: ([] = [
                { val: 2, kbn_name: "承認（ログイン可能にする）" },
                { val: 4, kbn_name: "申込否認" },
            ]),
            //入力v-model
            inp: {
                memberId: "",
                memberCd: "",
                password: "",
                corpName: "",
                corpKana: "",
                memberName: "",
                memberKana: "",
                tantouName: "",
                post: "",
                lat:"",
                lng:"",
                address1: "",
                address2: "",
                tel: "",
                fax: "",
                mail: "",
                statusFlg: "2",
                comment: "",
                appDate: "",
                mailSendFlg:false,
                mailContent:'',
                mailSubject:'',
                mailFrom:'',
                mailTo:'',

            },

            //エラー表示用
            err: ({} = {
                memberId: [],
                memberCd: [],
                password: [],
                corpName: [],
                corpKana: [],
                memberName: [],
                memberKana: [],
                tantouName: [],
                post: [],
                lat: [],
                lng: [],
                address1: [],
                address2: [],
                tel: [],
                fax: [],
                mail: [],
                statusFlg: [],
                comment: [],
                appDate: [],

                //mail
                mailContent:'',
                mailSubject:'',
                mailFrom:'',
                mailTo:'',
            }),
        };
    },
    mounted() {
        this.memberId = this.$route.params.memberId;
        // 会員情報の取得
        this.getMember();
    },
    methods: {
        /**
         * パスワード自動生成
         */
        clickGetPassword(){
            var letters = 'abcdefghijklmnopqrstuvwxyz';
            var numbers = '123456789';
            var symbols = '!#&';
            var passStr  = '';

            passStr = this.passGenerate(letters, 5) + this.passGenerate(letters.toUpperCase(), 2) + this.passGenerate(numbers, 2) + this.passGenerate(symbols, 1);

            //パスワードのシャッフル処理
            var a = passStr.split(""),
                n = a.length;

            for(var i = n - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var tmp = a[i];
                a[i] = a[j];
                a[j] = tmp;
            }
            this.inp.password =  a.join("");
            
        },
        /**
         * パスワードをランダム生成する関数
         */
        passGenerate(val, len){
            var pass = '';
            for(var i =0  ; i < len ; i++){
                pass += val.charAt(Math.floor(Math.random() * val.length));
            }
            return pass; 
        },

        /**
         * 一覧へもどるボタンクリック
         */
        clickCancel(){
            this.$router.go(-1);
        },
        clickBackStep1(){
                this.valStep = 1;
        },
        /**
         * 送信メール内容作成
         */
        convMailContent(){
            this.inp.mailFrom = this.mailCont.mail_from;
            this.inp.mailSubject = this.mailCont.subject;
            this.inp.mailContent= this.mailCont.content;
            this.inp.mailContent = this.inp.mailContent.replace('%corp_name%', this.inp.corpName);
            this.inp.mailContent = this.inp.mailContent.replace('%corp_kana%', this.inp.corpKana);
            this.inp.mailContent = this.inp.mailContent.replace('%member_name%', this.inp.member_name);
            this.inp.mailContent = this.inp.mailContent.replace('%member_kana%', this.inp.member_kana);
            this.inp.mailContent = this.inp.mailContent.replace('%post%', this.inp.post);
            this.inp.mailContent = this.inp.mailContent.replace('%fax%', this.inp.fax);
            this.inp.mailContent = this.inp.mailContent.replace('%mail%', this.inp.mail);
            this.inp.mailContent = this.inp.mailContent.replace('%member_cd%', this.inp.memberCd);
            this.inp.mailContent = this.inp.mailContent.replace('%password%', this.inp.password);
        },
        /**
         * Step1
         */
        async clickStep1(){
            this.clearError();
            if(this.inp.statusFlg == 2){

                // 入力チェック
                if(await this.inputCheckStep1()){
                    this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007); 
                    return ;
                }
                this.convMailContent(); 
                this.valStep = 2;
            }else{

                // 確認メッセージ
                var res = await this.swalConfirm(
                    MESSAGE.TITLE_CONFIRM,
                    MESSAGE.R002);
                if(!res){
                    this.saveLoading = null;
                    return ;
                }

                this.inp.mailSendFlg = false;
                if(await this.approvalMember()){
                    this.valStep = 3; 
                    this.compStep3 = true;
                }
            }
        },
        /**
         * ステップ２
         */
        async clickStep2(){
            this.inp.mailSendFlg = true;
            this.clearError();

            // 入力チェック
            if(await this.inputCheckStep2()){
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return ;
            }

            // 更新処理
            if(await this.approvalMember()){
                this.valStep = 3; 
                this.compStep3 = true;
            }

        },
        /**
         * 入力チェック
         */
        async inputCheckStep1(){

            //-- 会員CD
            if (this.inp.memberCd === "") {
                this.err.memberCd = [MESSAGE.formatByArr(MESSAGE.E001,'会員CD')];
            }else{
                if(!this.isHanEisu(this.inp.memberCd)){
                    this.err.memberCd = [MESSAGE.E003];
                }else{
                    if(this.inp.memberCd !== this.memberCdDef){
                        if(await this.isMemberCd()){
                            this.err.memberCd = [MESSAGE.formatByArr(MESSAGE.E010,'会員CD')];
                        }
                    }
                }
            }

            //-- パスワード 
            if (this.inp.password === "") {
                this.err.password = [MESSAGE.formatByArr(MESSAGE.E001,'パスワード')];
            }else{
                if(!this.isHanEisuKigo(this.inp.password) || this.meterPoint < 75){
                    this.err.password = [MESSAGE.formatByArr(MESSAGE.E001,'正しい値')];
                }
            }

            //-- 緯度・経度 
            if (this.inp.lat && this.latlngFormat(this.inp.lat)) {
                this.err.lat = [MESSAGE.formatByArr(MESSAGE.E001,'正しい値')];
            }else{
                if(this.inp.lat && !this.inp.lng){
                    this.err.lng = [MESSAGE.formatByArr(MESSAGE.E001,'経度')];
                }
            }
            if (this.inp.lng && this.latlngFormat(this.inp.lng)) {
                this.err.lng = [MESSAGE.formatByArr(MESSAGE.E001,'正しい値')];
            }else{
                if(!this.inp.lat && this.inp.lng){
                    this.err.lat = [MESSAGE.formatByArr(MESSAGE.E001,'緯度')];
                }
            }

            // エラーがあった場合は true
            for (var key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        async inputCheckStep2(){

            //-- 送信先（TO）
            // if (this.inp.mail === "") {
            //     this.err.mail = [MESSAGE.formatByArr(MESSAGE.E001,'メールアドレス')];
            // }else{
            //     if (this.inp.mail !== "" && !this.isMailaddress(this.inp.mail)) {
            //         this.err.mail = [MESSAGE.formatByArr(MESSAGE.E005, "メールドレスの形式")];
            //     }
            // }

            //-- タイトル 
            if (this.inp.mailSubject === "") {
                this.err.mailSubject = [MESSAGE.formatByArr(MESSAGE.E001,'タイトル')];
            }

            //-- 本文 
            if (this.inp.mailContent === "") {
                this.err.mailContent = [MESSAGE.formatByArr(MESSAGE.E001,'本文')];
            }

            // エラーがあった場合は true
            for (let key in this.err) {
                if (this.err[key].length > 0) return true;
            }

            return false;
        },
        /**
         * 会員情報更新処理（承認更新）
         */
        async approvalMember(){
            return await this.axios.put(`/api/member/${this.inp.memberId}`,this.inp).then((res)=>{
                        if(res.data.result != 0){
                            this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                            this.err.mail = [MESSAGE.formatByArr(MESSAGE.E005, "メールの形式")];
                            return false;
                        }
                        this.saveLoading = null;
                        this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,`会員情報`)  );
                        return true;
            });
        },
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        /**
         * 会員重複チェック
         */
        async isMemberCd(){
              return  await this.axios.get(`/api/member/is_member_cd/${this.inp.memberCd}`).then((res)=>{
                            this.saveLoading = null;
                            if(res.data.result != 0){
                                // true:同一の会員CDは存在している
                                return true;
                            }
                            return false;
                });
        },
        /**
         * 会員情報取得
         */
        getMember() {
            this.axios.get('/api/mail/5').then((res)=>{
                if(res.data.result != 0){
                    return ;
                }
                this.mailCont = res.data.mailCont;

            });
            this.axios.get(`/api/member/${this.memberId}`).then((res) => {
                if (res.data.result != 0) {
                    return;
                }
                const memberInf = res.data.memberInf;
                this.inp.memberId = memberInf.member_id;
                this.inp.memberCd = this.memberCdDef = this.getVal(memberInf.member_cd);
                this.inp.password = this.getVal(memberInf.password);
                this.inp.corpName = memberInf.corp_name;
                this.inp.corpKana = memberInf.corp_kana;
                this.inp.memberName = memberInf.member_name;
                this.inp.memberKana = memberInf.member_kana;
                this.inp.tantouName = memberInf.tantou_name;
                this.inp.post = memberInf.post;
                this.inp.lat = memberInf.lat;
                this.inp.lng = memberInf.lng;
                this.inp.address1 = memberInf.address1;
                this.inp.address2 = memberInf.address2;
                this.inp.tel = memberInf.tel;
                this.inp.fax = memberInf.fax;
                this.inp.mail = memberInf.mail;
                this.inp.statusFlg = 2 
                this.inp.comment = memberInf.comment;
                this.inp.appDate = memberInf.app_date;
            });
        },
        // 緯度経度マップ表示
        clickMap(){
            var param = {
                lat:this.inp.lat,
                lng:this.inp.lng,
            }
            this.$refs.refDlgMap.open(param)
        },
        callBackMap(latlng){
            this.inp.lat = latlng.lat;
            this.inp.lng = latlng.lng;
        },
    },
};
</script>