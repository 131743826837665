<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >QRコード印刷注文状況</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mx-auto mb-4">
            <v-system-bar color="gray" height="50">
                <v-card-subtitle class="text-h6 font-weight-bold"
                    >抽出条件</v-card-subtitle
                >
                <v-spacer />
                <v-btn @click="clickExpand" small outlined>
                    <template v-if="expand">Close</template>
                    <template v-else>Open</template>
                </v-btn>
            </v-system-bar>
            <v-expand-transition>
                <v-container fluid v-show="expand">
                    <v-row class="mt-0 ml-2" dense>
                        <v-col>
                            <div class="d-inline-flex">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >注文番号
                                </v-subheader>
                                <v-text-field
                                    v-model="search.chumonNo"
                                    class="ma-2 hatchu-cd"
                                    maxlength="20"
                                    type="tel"
                                    ref="focusFlg"
                                    hide-details=""
                                    dense
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0 ml-2" dense>
                        <v-col>
                            <div class="d-inline-flex">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >会社CD
                                </v-subheader>
                                <v-text-field
                                    v-model="search.memberCd"
                                    class="ma-2 member-cd"
                                    maxlength="10"
                                    type="tel"
                                    ref="focusFlg"
                                    hide-details=""
                                    dense
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0 ml-2" dense>
                        <v-col>
                            <div class="d-inline-flex">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >会社名(発送先)
                                </v-subheader>
                                <v-text-field
                                    v-model="search.corpName"
                                    class="ma-2 member-name"
                                    maxlength="40"
                                    dense
                                    hide-details=""
                                    outlined
                                ></v-text-field>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0 ml-2" dense>
                        <v-col>
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >注文日
                                </v-subheader>
                                <input-date-fld
                                    :date.sync="search.chumonFrom"
                                />
                                <span class="mx-2">～</span>
                                <input-date-fld
                                    :date.sync="search.chumonTo"
                                />
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0 ml-2" dense>
                        <v-col>
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >発送日
                                </v-subheader>
                                <input-date-fld
                                    :date.sync="search.hassoFrom"
                                />
                                <span class="mx-2">～</span>
                                <input-date-fld
                                    :date.sync="search.hassoTo"
                                />
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0 ml-2" dense>
                        <v-col>
                            <div class="d-inline-flex align-center">
                                <v-subheader
                                    class="text-subtitle-1 font-weight-bold title"
                                    >印刷状況
                                </v-subheader>
                                <v-checkbox
                                        dense
                                        hide-details=""
                                        class="mt-0 pa-0 mr-5 d-inline-flex"
                                        v-model="search.status"
                                        v-for="(list, index) in statusList"
                                        :key="index"
                                        :label="list.name"
                                        :value="list.value"
                                    />
                            </div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <div class="text-center">
                                <v-divider class="mb-3" />
                                <v-btn large class="btn-close mr-4" @click="clickClear()"
                                    >クリア</v-btn
                                >
                                <v-btn large color="blue" @click="getData()"
                                    >検&nbsp;索</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-expand-transition>
        </v-card>
        <v-simple-table fixed-header class="datatable tbl-max-height" dense>
            <template v-slot:default>
                <col style="width: 50px" />
                <col style="width: 120px" />
                <col style="width: 200px" />
                <col style="width: 200px" />
                <col style="width: 180px" />
                <col style="width: 180px" />
                <col style="width: 150px" />
                <col style="width: 400px" />
                <col style="width: 100px" />
                <thead>
                    <tr>
                        <th>
                            <v-checkbox
                                v-model="allSelect"
                                class="chk-box"
                                value="on"
                                @change="selectAllPrint"
                                dense
                                hide-details
                            ></v-checkbox>
                        </th>
                        <th>状況</th>
                        <th>注文番号</th>
                        <th>発注番号</th>
                        <th>注文日</th>
                        <th>発送日</th>
                        <th>会社CD</th>
                        <th>会社名(発送先)</th>
                        <th>処理</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in chumonList" :key="item.chumon_no">
                        <td class="text-center">
                            <v-checkbox
                                v-model="chumonNoList"
                                class="chk-box"
                                :value="item.chumon_no"
                                @change="checkSelect(item.chumon_no)"
                                dense
                                hide-details
                            ></v-checkbox>
                        </td>
                        <td v-html="chumonStatus(item.status)"></td>
                        <td>{{ item.chumon_no }}</td>
                        <td>{{ item.hatchu_no }}</td>
                        <td>{{ dateFormat(item.chumon_date, 'YYYY-MM-DD HH:mm') }}</td>
                        <td>{{ item.hasso_date }}</td>
                        <td>{{ item.member_cd }}</td>
                        <td>{{ item.saki_corp_name }}</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="light-blue lighten-4"
                                @click="clickChumonDetail(item.chumon_no)"
                                >詳細</v-btn
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <div style="border: solid 2px #ddd; text-align:center"
            class="grey--text text-lighten-5"
            v-if="chumonList.length==0">該当するデータがありません。</div>
        <v-divider class="mb-1"></v-divider>

        <div class="text-h6 font-weight-black mt-3 mb-0 text-right">
            <v-spacer />
            <v-btn color="deep-orange lighten-3" @click="downloadExcel" :disabled="isDisable" :loading="isLoading"
                >注文情報(EXCEL)ダウンロード</v-btn>
        </div>
        <dlg-chumon-detail ref="refChumonDetailDialog" :dlg-call-back="dlgCallBack" />
    </v-container>
</template>
<style scoped>
.title {
    width: 150px;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px !important;
}
.chk-box{
    display: inline-flex;
    margin: 0 !important;
}
.chk-box .v-input--selection-controls__input, .chk-box .v-input--selection-controls__ripple{
    width: 20px !important;
    height: 20px !important;
    left: auto !important;
    top: auto !important;
    margin: 0 !important;
}
.tbl-max-height >>> .v-data-table__wrapper{
    max-height: 500px;
}
</style>
<script>
import { MESSAGE } from "../messages";
import InputDateFld from "../components/InputDateFld.vue";
import DlgChumonDetail from "../components/dialog/DlgChumonDetail.vue";
export default {
    components: { InputDateFld, DlgChumonDetail },
    computed: {
        isDisable(){
            return (this.chumonNoList.length<=0)||(this.disableFlg)?true:false;
        }
    },
    watch: {
    },
    data: () => ({
        //検索欄開閉
        expand: true,
        disableFlg: false,
        isLoading: false,

        // 検索条件
        search: {
            chumonNo:'',
            memberCd:'',
            corpName:'',
            chumonFrom:'',
            chumonTo:'',
            hassoFrom:'',
            hassoTo:'',
            status:[['0']],
        },

        //一覧情報
        chumonList: [],
        chumonNoList: [],
        allSelect: 'off',

        //ステータスリスト
        statusList: [
            { name: '未発注', value: ['0'] },
            { name: '発注済', value: ['1', '2'] },
            { name: '発送済', value: ['3'] },
        ],
    }),
    mounted() {
        this.disableFlg = false;
        this.isLoading = false;
        this.getData();
    },
    methods: {
        /**
         * 検索欄の開閉
         */
        clickExpand(){
            this.expand = !this.expand;
        },
        /**
         * 発注中データ取得
         */
        getData(){
            this.axios.post("/api/admin-get-chumon", this.search).then((res) => {
                this.chumonList = res.data.resultData.chumonList;

                //チェックボックス初期化
                this.chumonNoList = [];
                this.allSelect = 'off';
            });
        },
        /**
         * 検索クリアボタン
         */
        async clickClear() {
            for(let key in this.search){
                this.search[key] = '';
            }
            this.search.status = [['0']];

            this.getData();
        },
        async downloadExcel(){
            this.disableFlg = true;
            this.isLoading = true;

            await this.axios.post("/api/admin-excel-chumon", {chumonNoList: this.chumonNoList}, {responseType: "blob"}).then( res => {
                const fileURL = window.URL.createObjectURL(new Blob([res.data]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', "chumon_inf"+".xlsx");
                document.body.appendChild(fileLink);
                fileLink.click();
            }).catch(err => {});

            this.disableFlg = false;
            this.isLoading = false;
        },
        /**
         * チェックボックス(単体)
         */
        checkSelect(){
            if(this.allSelect=='on'){
                this.allSelect = 'off';
            }
        },
        /**
         * チェックボックス(すべて)
         */
        selectAllPrint: function () {
            this.chumonNoList = [];
            if(this.allSelect=='on'){
                this.chumonList.forEach((element) => {
                    this.chumonNoList.push(element.chumon_no);
                })
            }
        },
        /**
         * 注文詳細ダイアログのオープン
         */
        clickChumonDetail(no) {
            this.$refs.refChumonDetailDialog.open(no);
        },
        dlgCallBack() {
        },
    },
};
</script>