<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
		<v-sheet
			elevation="2"
			rounded="0"
			height="40"
			max-width="100%"
			class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
		>
			<v-icon class="white--text mb-2">mdi-database</v-icon>
			マスタ管理・メンテナンス 
		</v-sheet>
		<v-card class="ma-5 rounded" >
			<v-system-bar color="gray" height="50">
				<v-card-subtitle class="text-h6 font-weight-bold">
					資料ダウンロード管理	
				</v-card-subtitle>
				<v-spacer />
			</v-system-bar>
		</v-card>
	</v-card >
	<v-card class="ma-5 pa-5" outlined >
		<div class="my-5">
			<v-simple-table class="datatable" style="width:700px">
				<tr>
					<th> 
						表示切替
					</th>
					<td>
						<v-row class="ma-1">
						<v-checkbox class="mr-7" hide-details="auto" true-value="1" false-value="0" v-model="dispMemberFlg"  dense label="会員に表示" ></v-checkbox>
						<v-checkbox hide-details="auto" true-value="1" false-value="0" v-model="dispGeneralFlg" dense label="一般に表示" ></v-checkbox>
						</v-row>
					</td>
				</tr>
			</v-simple-table>
		</div>
		<v-tabs
		v-model="tabValue"
		@change="changeTab"
		show-arrows>
			<v-tab
			v-for="category in categorys"
			:key="category.category_id"
			>{{category.category_name}}

			<v-btn x-small fab  depressed text class="mx-1 pa-0" @click="clickEdit(category)">
				<v-icon>
					mdi-application-edit-outline
				</v-icon>
				</v-btn>

			<v-btn x-small fab style="width: 30px !important;"  depressed text class="mx-1 pa-0" @click="clickDel(category)">
			<v-icon>
				mdi-trash-can-outline
			</v-icon>

			</v-btn>
			</v-tab>
			<v-tab @click="clickAdd" key="99">
			<v-icon>
				mdi-plus-circle-outline
			</v-icon>
			</v-tab>
		</v-tabs>
		<div class="category">
			<v-simple-table class="ma-5 datatable" v-if="tabValue">
				<colgroup>
				<col style="width:80px;" />
				<col style="width:120px;" />
				<col style="width:60px;" />
				<col style="width:60px;" />
				<col style="width:120px;" />
				<col style="width:auto;" />
				<col style="width:200px;" />
				</colgroup>
				<tr>
					<th class="py-2">No</th>
					<th>日付</th>
					<th>会員</th>
					<th>一般</th>
					<th>種別</th>
					<th>ファイル・リンク</th>
					<th>処理</th>
				</tr>
				<tr v-for="(row, index) in siryos" :key="row.siryo_id">
					<td class="py-2 text-center">{{index+1}}</td>
					<td class="text-center">{{dateFormat(row.open_date)}}</td>
					<td class="text-center">
						<div v-if="row.disp_member_flg==1">○</div>
					</td>
					<td class="text-center">
						<div v-if="row.disp_general_flg==1">○</div>
					</td>
					<td class="text-center">
						<template v-if="row.siryo_kbn===1">ファイル</template>
						<template v-if="row.siryo_kbn===2">リンク</template>
					</td>
					<td class="text-left">
						<template v-if="row.siryo_kbn===1">
							<v-btn text @click="clickDownload(row)" class="post-link">
							{{row.siryo_title}}
							</v-btn>
						</template>
						<template v-if="row.siryo_kbn===2">
							<v-btn
								:href="row.siryo_url"
								target="_blank"
							 text  class="post-link">
							{{row.siryo_title}}
							</v-btn>
						</template>
					</td>
					<td class="text-center">
						<v-btn class="mr-4" color="light-blue lighten-4" small @click="updateSiryo(row)">編集</v-btn>
						<v-btn color="red lighten-4" small @click="delSiryo(row)">削除</v-btn>
					</td>

				</tr>
				<tr>
					<td colspan="7">
						<v-btn class="ma-2" @click="addSiryo">追加</v-btn>
					</td>
				</tr>
			</v-simple-table>
		</div>

	</v-card>
    <dlg-download-category ref="refDownloadCategory" :callback="callbackAddCategory"></dlg-download-category>
	<dlg-add-download ref="refAddDownload" :callback="callbackAddSiryo"></dlg-add-download>
	</v-container>
</template>
<style lang="scss" scoped>
.v-tab {
    border: 1px solid #ccc;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.category {
    border: 1px solid #ccc;
    min-height: 200px;
}
.post-link {
    color: #1976d2;
    display: inline-block;
    word-break: break-all;
    padding: 2px 8px;
    border-radius: 5px;
    text-transform: none !important;
    white-space: normal;
    max-width: 500px;
    height: inherit !important;
}
.v-tab--active {
    background-color: aliceblue;
}
</style>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant";
import DlgDownloadCategory from "../components/dialog/DlgDownloadCategory.vue"
import DlgAddDownload from "../components/dialog/DlgAddDownload.vue"
export default {
    components:{DlgDownloadCategory, DlgAddDownload},
	data() {
		return {
			tabValue:0,
			orgSiryos:[],
			siryos:[],
			categorys:[],
			dispMemberFlg:'1',
			dispGeneralFlg:'1',
		}
	},
	watch:{
		dispMemberFlg:function(oldVal,newVal){
			this.filterSiryo(this.tabValue)
		},
		dispGeneralFlg:function(oldVal,newVal){
			this.filterSiryo(this.tabValue)
		}
	},
	computed:{
	},
	async mounted(){
		await this.getCategory();
		if(this.categorys.length>0){
			this.tabValue=1;
		}
		await this.getSiryo();
		this.filterSiryo(this.tabValue);
	},
	methods:{
		/**
		 * タブの切替イベント
		 * @param {タブのID} tabNo 
		 */
		changeTab(tabNo){
			if(tabNo==0){
				this.siryos = [];
				return ;
			}
			this.filterSiryo(tabNo);
		},

		/**
		 *タブ切り替え時のリスト表示処理 
		 * @param {タブ番号} tabNo 
		 */
		filterSiryo(tabNo){
			this.siryos = [];
			if(tabNo==0){
				return;
			}

			const category_id = this.categorys[tabNo-1].category_id;
			const dispMemberFlg = this.dispMemberFlg;
			const dispGeneralFlg = this.dispGeneralFlg;
			this.siryos = this.orgSiryos.filter(function(item){
				if( item.category_id == category_id){
					if(dispMemberFlg=='1' && item.disp_member_flg==1){
						return true;	
					}
					if(dispGeneralFlg=='1' && item.disp_general_flg==1){
						return true;	
					}
					return false;
				}
			})
		},

		/**
		 * カテゴリのリスト取得
		 */
		async getCategory(){

			const res = await this.axios.post('/api/siryo/get-category');
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.categorys = res.data.resultData.categorys;
			}
		},

		/**
		 * 資料リスト取得
		 */
		async getSiryo(){

			const res = await this.axios.post('/api/siryo/get-siryo');
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.orgSiryos = res.data.resultData.siryos;
			}
		},

		/**
		 * 資料追加
		 */
		addSiryo(){
			
			this.$refs.refAddDownload.open(this.categorys[this.tabValue-1]);
		},
		updateSiryo(siryo){
			this.$refs.refAddDownload.open(this.categorys[this.tabValue-1], siryo);
		},

		/**
		 *	資料追加のコールバツク 
		 * @param {資料一覧} siryos 
		 */
		callbackAddSiryo(siryos)
		{
				this.orgSiryos = siryos;
				this.filterSiryo(this.tabValue);
		},

		/**
		 * カテゴリの追加ダイアログ表示
		 */
        clickAdd(){
            this.$refs.refDownloadCategory.open('Add');
        },
		clickEdit(category){
            this.$refs.refDownloadCategory.open('Edit', category);
			
		},
		/**
		 * カテゴリ追加のコールバック 
		 */
		callbackAddCategory(categorys){
			this.categorys = categorys;
			this.$router.go({path: this.$router.currentRoute.path, force: true})
		},
		chkDelete(id){
			for(const item of this.orgSiryos){
				if(item.category_id==id)
					return true;
			}
			return false;
		},
		/**
		 * カテゴリの削除 
		 * @param {カテゴリID} id 
		 */
		async clickDel(category){
			if(this.chkDelete(category.category_id)){

                this.swalAlert(MESSAGE.TITLE_ERROR,'ファイル・リンクが存在するので削除できません。');
				return ;
			}
            const ret = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, 'カテゴリ(' + category.category_name + ')'));

			if(!ret){
                return ;
            }

			const res = await this.axios.post('/api/siryo/del-category',{category_id:category.category_id});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.categorys = res.data.resultData.categorys;
				if(this.categorys.length>0){
					this.tabValue=1;
					this.filterSiryo(this.tabValue);
				}
			}
		},

		/**
		 * 資料の削除 
		 * @param {資料ID} siryo 
		 */
		async delSiryo(siryo){
            const ret = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003, '資料('+siryo.siryo_title+')'));

            if(!ret){
                return ;
            }

			const res = await this.axios.post('/api/siryo/del-siryo',{siryo_id:siryo.siryo_id});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				this.orgSiryos = res.data.resultData.siryos;
				this.filterSiryo(this.tabValue);
			}
		},

		/**
		 * ダウンロードボタンクリック 
		 * @param {資料情報} siryo 
		 */
		async clickDownload(siryo){
			const res = await this.axios.post('/api/siryo/get-siryo-file',{siryo_id:siryo.siryo_id});
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
				const blob = this.toBlob(res.data.resultData.siryo_base64_file, res.data.resultData.siryo_file_mime_type);
				const fileName = res.data.resultData.siryo_dl_name;
				var link = document.createElement('a');
				document.body.appendChild(link);
				link.href = URL.createObjectURL(blob);
				link.target = '_blank';
				link.download = fileName; 
				link.click();
				document.body.removeChild(link);

			}
		},
    	toBlob(data64, mime_ctype) {
			var bin = atob(data64.replace(/^.*,/, ''));
			var buffer = new Uint8Array(bin.length);
			for (var i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i);
			}
			// Blobを作成
			try {
				var blob = new Blob([buffer.buffer], {
					type: mime_ctype,
				});
			} catch (e) {
				return false;
			}
			return blob;
    	},
		
	}


}
</script>