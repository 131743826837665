<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >お知らせ管理</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-subheader class="text-h6 font-weight-black mt-3 mb-0"
            >お知らせ一覧
            <v-spacer />
            <v-btn color="deep-orange lighten-3" @click="clickNew"
                >お知らせ新規作成</v-btn
            >
        </v-subheader>
        <v-divider class="mb-1"></v-divider>
        <v-simple-table fixed-header height="auto" class="datatable" dense>
            <template v-slot:default>
                <col style="width: 80px" />
                <col style="width: 120px" />
                <col style="width: 110px" />
                <col style="width: auto" />
                <col style="width: 200px" />
                <thead>
                    <tr>
                        <th>CD</th>
                        <th>日付</th>
                        <th>表示区分</th>
                        <th>タイトル</th>
                        <th>編集</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in infoList" :key="item.id">
                        <td class="text-center">{{ item.id }}</td>
                        <td class="text-center" >{{  dateFormat(item.disp_date) }}</td>
                        <td class="text-center" :class="[{'red--text':(item.disp_flg==0)},{'blue--text':(item.disp_flg==1)}]">
                            {{ getJyotaiName(item.disp_flg) }}
                        </td>
                        <td class="text-left">{{ item.title }}</td>
                        <td class="text-center">
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="light-blue lighten-4"
                                :disabled="item.status_flg == 1"
                                @click="clickEdit(item)"
                                >編集</v-btn
                            >
                            <v-btn
                                small
                                class="ml-1 mr-1"
                                color="red lighten-4"
                                @click="clickDelete(item.id)"
                                >削除</v-btn
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-container>
</template>
<style scope>
.title {
    width: 110px;
}
.input-control {
    width: 100%;
}
.datatable table,
th,
tr,
td {
    border: solid 1px #ddd;
}
.datatable th {
    color: white !important;
    background-color: #999 !important;
    font-weight: 800;
    font-size: medium !important;
}
.datatable td {
    padding: 3px !important;
}
</style>
<script>
import { MESSAGE } from "../messages";
export default {
    name: "Member",
    components: {},
    data() {
        return {
            jyotai: ([] = [
                { val: 1, kbn_name: "表示する" },
                { val: 0, kbn_name: "表示しない" },
            ]),

            infoList: [],
        };
    },
    mounted() {
        this.getInformation();
    },
    methods: {
        /**
         * お知らせ一覧を取得
         */
        getInformation() {
            this.axios.get("/api/information").then((res) => {
                this.infoList = res.data.information;
            });
        },
        /**
         * 表示・非表示文言変換
         */
        getJyotaiName(kbn) {
            const nm = this.jyotai.find((row) => row.val === kbn);
            if (!nm) {
                return "";
            }
            return nm.kbn_name;
        },
        /**
         * 編集ボタン 
         */
        clickEdit(info) {
            this.$router.push({
                name: "InformationEdit",
                params: { id: info.id },
            });
        },
        /**
         * 削除ボタン
         */
        async clickDelete(id) {
            const res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R003,id));

            // 「いいえ」の場合、処理しない
            if (!res) {
                return;
            }
            
            await this.axios.delete(`/api/information/${id}`)
             .then((res) => {
                this.swalToast(MESSAGE.formatByArr(MESSAGE.I003,id));
                
                this.getInformation();
             });

        },
        /**
         * 新規作成ボタン
         */
        clickNew() {
            this.$router.push({ name: "InformationEdit", params: { id: "" } });
        },
    },
};
</script>

