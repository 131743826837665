<template>
    <v-dialog v-model="dialog" persistent max-width="300px">
        <v-card class="pb-5">
            <v-toolbar color="teal darken-3" height="40" dark>
                <v-toolbar-title>アップロードダイアログ</v-toolbar-title>
                <v-spacer />
                <v-icon @click="clickClose">mdi-close</v-icon>
            </v-toolbar>
            <v-card class="ma-0">
                <v-card outlined class="pa-5 d-flex">
                    <input
                        style="display: none"
                        type="file"
                        id="inputUpFile"
                        accept="application/pdf"
                        ref="upfile"
                        @change="selectedFile()"
                    />
                    <v-btn
                        height="30"
                        class="btn-edit"
                        @click="clickSelectFile"
                        :disabled="loading"
                    >
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                    <v-btn
                        height="30"
                        :disabled="isBtnUp || loading"
                        class="btn-new ml-3"
                        @click="clickUpload"
                        :loading="loading"
                        >更新開始</v-btn
                    >
                </v-card>
            </v-card>
            <v-card class="ma-3 text-right" elevation="0">
                <v-btn color="teal lighten-4" @click="clickClose"> 閉じる </v-btn>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<style lang="scss" scoped>
.successList {
    color: green;
    overflow-y: auto;
    max-height: 200px;
}
.errorList {
    color: red;
    overflow-y: auto;
    max-height: 200px;
}
.fileName {
    width: 300px;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>
<script>
import { CONSTANT } from "../../constant";
import { MESSAGE } from "../../messages";
export default {
    props: {
        dlgCallBack:{
            type:Function,
            require:true
        },
        targetName:{
            type: String,
            default: 'Upload'
        },
        url:{
            type: String,
            require:true
        }
    },
    data(){
        return{
        dialog: false,
        file: {},
        isBtnUp: true,
        loading:false,
    }},
    methods: {
        /**
         * ダイアログオープン
         */
        open() {
            this.dialog = true;
            this.isBtnUp = true;
            this.loading = false;
            this.file = {};
        },
        /**
         * ファイル選択イベント
         * ファイルが選択された時に発火
         */
        selectedFile() {
            if(this.$refs.upfile.files.length === 0)return
            // this.loading = false

            const file = this.$refs.upfile.files[0];
            this.isBtnUp = false;
            const reader = new FileReader();
            
            if(file){
                reader.readAsDataURL(file);
            }
            reader.onload=()=>{
                this.fileData = reader.result;//.replace(/data:.*\/.*;base64,/, '');
            }
            this.$refs.upfile.value = null
        },
        /**
         * 
         * 
         * 
         * 選択ボタンクリック
         */
        clickSelectFile() {
            //input type=file をクリック
            this.$refs.upfile.click();
            //アップロード中のローディング処理
            // this.loading = true
        },
        clickClose(){
            this.dlgCallBack();
            this.dialog = false;
            
        },
        /**
         * ファイルアップロード処理
         */
        async clickUpload() {
           var res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.formatByArr(MESSAGE.R005, this.targetName)
            );
            if (!res) {
                this.loading = false;
                return;
            }
            this.loading = true
            const fileInfo ={
                print_file_data: this.fileData,

            }
            this.axios
                .post("/api/admin-upload-print-mst", fileInfo)
                .then((res) => {
                    if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                        this.loading=false;
                        this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,'資料'));
                        this.dialog = false;
                        this.dlgCallBack();
                        return
                    }
                });

        },
    },
};
</script>