<template>
   <v-container grid-list-md text-xs-center class="timeout-layout">
        <v-card elevation="8" width="50%" height="300" outlined class="ma-auto">
            <v-card-title class="d-flex justify-space-around">
               {{errTitle}} 
            </v-card-title>
            <v-card-text class="d-flex justify-space-around"> 
                <div class="err-msg">
                    {{errMsg}}
                </div> 
            </v-card-text>
            <v-card-actions class="d-flex justify-space-around">
                <v-btn class="btn-new" @click="goLogin">Login</v-btn>
            </v-card-actions>
        </v-card>
   </v-container>
</template>
<style>
.err-msg{
    height: 100px;
    width: 80%;
    font-size: 12pt;
    font-weight: 800;
    padding: 20px ;
    background: pink;
    border:1px solid #ccc;
    border-radius: 8px;
}
.timeout-layout{
    height: 100%;
    padding-top: 120px;
}
</style>
<script>
import { MESSAGE } from "../messages";

export default {
    data() {
        return {
            errTitle:'タイムアウトしました',
            errType:'1',
            errMsg:'',
        }
    },
    mounted(){
        if(this.$route.params.errType){
            this.errType = this.$route.params.errType;
        }
        switch(this.errType){
            case '1':
                this.errTitle = 'タイムアウトしました'
                this.errMsg = "セッションの有効期限が切れました。恐れ入りますが「ログイン」から操作をやり直して下さい"
                break;
        }

    },
    methods: {
        goLogin(){

            this.$router.push("/login");
        }
    },

}
</script>