<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >承認メール再送信</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-card class="mb-12">
            <v-simple-table
                class="sms-list-table approval mt-4 pt-4 pr-4 pl-4 pb-4"
            >
                <tr>
                    <th>送信元（FROM）</th>
                    <td>
                        <div class="my-3 ml-3">
                            <v-sheet
                                outlined
                                width="500"
                                class="rounded pa-2 dspfld"
                                elevation="0"
                            >
                            {{ inp.mailFrom }}
                            </v-sheet>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>送信先（TO）</th>
                    <td>
                        <div class="my-3 ml-1">
                            <v-text-field
                                outlined
                                maxlength="60"
                                type="tel"
                                :error="isError(err.mailTo)"
                                hide-details=""
                                dense
                                class="ma-2 mailTo"
                                elevation="0"
                                v-model="inp.mailTo"
                            >
                            </v-text-field>
                        </div>
                        <div
                            class="controll-error ml-3"
                            v-show="isError(err.mail)"
                        >
                            <span v-html="dispError(err.mail)"></span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>タイトル</th>
                    <td>
                        <div class="my-3 ml-1">
                            <v-text-field
                                solo
                                maxlength="40"
                                type="tel"
                                outlined
                                :error="isError(err.mailSubject)"
                                hide-details="auto"
                                dense
                                class="ma-2 mail-subject"
                                v-model="inp.mailSubject"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.mailSubject)"
                            >
                                <span
                                    v-html="dispError(err.mailSubject)"
                                ></span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th>本文</th>
                    <td>
                        <div class="my-3 ml-1">
                            <v-textarea
                                solo
                                maxlength="1000"
                                outlined
                                :error="isError(err.mailContent)"
                                hide-details="auto"
                                dense
                                auto-grow
                                class="ma-2"
                            v-model="this.inp.mailContent"
                            />
                            <div
                                class="controll-error ml-2"
                                v-show="isError(err.mailContent)"
                            >
                                <span
                                    v-html="dispError(err.mailContent)"
                                ></span>
                            </div>
                        </div>
                    </td>
                </tr>
            </v-simple-table>
        </v-card>
        <v-btn class="btn-close ma-2" @click="clickCancel"> もどる </v-btn>
        <v-btn class="btn-edit ma-2" width="140" @click="clickSubmit"> 送信する 
            <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
    </v-container>
</template>
<style lang="scss">
    .approval{
        th {
            width: 150px;
        }
        .dspfld {
            border: 1px solid #aaa;
            background-color: #eee;
            min-height: 40px;
        }
    }
    .mailTo{
        width:500px !important;
    }
</style>
<script>
import { MESSAGE } from "../messages";
export default {
    data(){
        return {
            memberId:'',

            //入力v-model
            inp:{
                mailFrom:'',
                mailSubject:'',
                mailContent:'',
                mailTo:'',
                subject:'',
            },

            //エラー表示用
            err: ({} = {
                mailFrom:[],
                mailSubject:[],
                mailContent:[],
                mailTo:[],
                subject:[],
            }),
        };
    },
    mounted(){
        this.memberId = this.$store.state.memberId;
        // 会員情報の取得
        this.getMember();
    },
    methods:{
        /**
        * 会員情報取得
        */
        async getMember() {
            let res = await this.axios.get('/api/mail/5');
            if(res.data.result != 0){
                return ;
            }
            const mailInf = res.data.mailCont;
            this.inp.mailContent = mailInf.content;
            this.inp.mailFrom =mailInf.mail_from;
            this.inp.mailSubject = mailInf.subject;

            res = await this.axios.get(`/api/member/${this.memberId}`);
            if (res.data.result != 0) {
                return;
            }
            const memberInf = res.data.memberInf;   
            // 送信メール内容作成
            this.inp.mailTo = memberInf.mail[0];
            this.inp.mailContent = this.inp.mailContent.replace('%corp_name%',memberInf.corp_name);
            this.inp.mailContent = this.inp.mailContent.replace('%corp_kana%', memberInf.corp_kana);
            this.inp.mailContent = this.inp.mailContent.replace('%member_name%', memberInf.member_name);
            this.inp.mailContent = this.inp.mailContent.replace('%member_kana%', memberInf.member_kana);
            this.inp.mailContent = this.inp.mailContent.replace('%post%', memberInf.post);
            this.inp.mailContent = this.inp.mailContent.replace('%fax%', memberInf.fax);
            this.inp.mailContent = this.inp.mailContent.replace('%mail%', memberInf.mail);
            this.inp.mailContent = this.inp.mailContent.replace('%member_cd%', memberInf.member_cd);
            this.inp.mailContent = this.inp.mailContent.replace('%password%', memberInf.password);
        },
        /**
         * 入力チェック
         */
        async inputCheck(){
            this.clearError();
            // -- 送信元
            if(!this.inp.mailFrom){
                this.err.mailFrom = [MESSAGE.formatByArr(MESSAGE.E001,'送信元')];
            }

            // -- 送信先
            if(!this.inp.mailTo){
                this.err.mailTo = [MESSAGE.formatByArr(MESSAGE.E001,'送信先')];
            }

            // -- タイトル
            if(!this.inp.mailSubject){
                this.err.mailSubject = [MESSAGE.formatByArr(MESSAGE.E001,'タイトル')];
            }

            // -- 本文
            if(!this.inp.mailContent){
                this.err.mailContent = [MESSAGE,formatByArr(MESSAGE.E001,'本文')];
            }

            // エラーがあった場合は true
            for (var key in this.err) {
                if (this.err[key].length > 0) return true;
            }
            return false;
        },
        /**
         * 送信ボタンクリック
         */
        async clickSubmit(){
            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            let res = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                MESSAGE.R006);
            if(!res){
                return ;
            }
            // メール送信
            res = await this.axios.post(`/api/member/approval-send-mail`,this.inp);
            if (res.data.result != 0) {
                return true;
            }
            // アラート
            await this.swalAlert(MESSAGE.TITLE_CONFIRM, MESSAGE.I005,MESSAGE.ICON_SUCCESS);
            this.clickCancel();
        },
        /**
         * エラーの一括クリア
         */
        clearError(){
            for (var key in this.err) {
                this.err[key] = [];
            }
        },
        /**
         * 一覧へもどる
         */
        clickCancel(){
            this.$router.push('member');
        },
    }
}
</script>