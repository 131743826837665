<template>
    <v-container fluid class="mt-2">
        <v-card class="mx-auto mb-2">
            <v-system-bar color="teal darken-3" height="40">
                <v-card-subtitle class="text-h6 font-weight-bold white--text"
                    >ダッシュボード</v-card-subtitle
                >
                <v-spacer />
            </v-system-bar>
        </v-card>
        <v-row>
            <v-col cols="5">
                <div class="dash-parts">
                    <div class="pa-1 rounded-t-lg title">
                        <v-icon> mdi-checkbox-marked</v-icon>
                        稼働状況
                    </div>
                    <div class="pa-5" style="border: 1px solid green">
                        <template v-if="statusFlg==true">
                        <div class="content-lg green--text text--darken-4">
                            <v-icon color="green">mdi-play-box-outline</v-icon>
                            稼働中
                        </div>
                        </template>
                        <template v-if="statusFlg==false">
                        <div class="content-lg red--text text--darken-4">
                            <v-icon color="red">mdi-stop</v-icon>
                           停止中 
                        </div>
                        </template>
                    </div>
                </div>
            </v-col>
            <v-col cols="5">
                <div class="dash-parts">
                    <div class="pa-1 rounded-t-lg title">
                        <v-icon> mdi-checkbox-marked</v-icon>
                       会員状況 
                    </div>
                    <div class="pa-5" style="border: 1px solid green">
                        <v-row>
                            <v-col cols="5" class="text-h6">
                                新規会員申込数
                            </v-col>
                            <v-col cols="4" class="text-right text-h6">
                               {{newCnt}} 件 
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="5" class="text-h6">
                               稼働中会員数 
                            </v-col>
                            <v-col cols="4" class="text-right text-h6">
                               {{kadoCnt}} 件 
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<style lang="scss" scoped>
.box-parts {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    .box-parts-title {
        font-size: 13pt;
        font-weight: 800;
    }
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
export default {
    name: "Member",
    components: {},
    data() {
        return {
            kadoCnt:0,
            newCnt:0,
            systemInfo:{},
            statusFlg: null,
        };
    },
    mounted() {
        this.getDashbord();
    },
    methods: {
        /**
         * お知らせ一覧を取得
         */
        getDashbord() {
            this.axios.get("/api/admin-dashbord-info").then((res) => {
                if(res.data.result==0){

                    this.kadoCnt = res.data.kadoCnt;
                    this.newCnt  = res.data.newCnt;
                    this.systemInfo = res.data.systemInfo;

                    // 停止設定時間内なら、「停止」を表示する。
                    var dateNow = moment(new Date).format("YYYY-MM-DD HH:mm");
                    var getDateStart = moment(this.systemInfo.mainte_start_date).format("YYYY-MM-DD HH:mm");
                    var getDateEnd = moment(this.systemInfo.mainte_end_date).format("YYYY-MM-DD HH:mm");
                    
                    if(this.systemInfo.system_status == 1 || (this.systemInfo.system_status == 2 && getDateStart <= dateNow && dateNow < getDateEnd)){
                        return this.statusFlg = false;
                    }
                    return this.statusFlg = true;
                }
            });
        },
    },
};
</script>
